<template>
  <div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">透明度</div>
      <div class="diy-group-content">
        <div class="check-color d-flex align-items-center">
          <el-slider
            v-model="classItem.param.classify_opacity"
            class="slider_text"
            @change="sliderChange" :show-tooltip="false"
            :format-tooltip="formatTooltip"
          ></el-slider>
          <div class="check_color">{{ slidevalue }}%</div>
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">字体颜色</div>
      <div class="diy-group-content">
        <div class="check-color d-flex align-items-center">
          <el-color-picker
            v-model="classItem.param.classify_font_color"
            @change="fontChange"
          ></el-color-picker>
          <div class="check_color">
            {{ classItem.param.classify_font_color }}
          </div>
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">背景颜色</div>
      <div class="diy-group-content">
        <div class="check-color d-flex align-items-center">
          <el-color-picker
            v-model="classItem.param.classify_background_color"
            @change="backgroundChange"
          ></el-color-picker>
          <div class="check_color">
            {{ classItem.param.classify_background_color }}
          </div>
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">分类样式</div>
      <div class="diy-group-content d-flex align-items-center ">
        <div class="check-color d-flex align-items-center">
          <el-radio-group v-model="classItem.param.classify_style" @change="styleChange">
            <el-radio :label="1">样式一</el-radio>
            <el-radio :label="2">样式二</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["param"],
  name: "classify",
  data() {
    return {
      classItem: this.param,
      slidevalue:70,
    };
  },
  created() {
    this.classItem.param.classify_opacity=this.classItem.param.classify_opacity*100
  },
  methods: {
    fontChange(value) {
      this.classItem.param.classify_font_color = value;
      this.pubilcCom();
    },
    backgroundChange(value){
      this.classItem.param.classify_background_color = value;
      this.pubilcCom();
    },
    formatTooltip(val) {
      return val / 100;
    },
    sliderChange(value) {
      this.classItem.param.classify_opacity = value;
      this.slidevalue= value;
      this.pubilcCom();
    },
    pubilcCom() {
      let data = this.classItem;
      this.$emit("change", data);
    },
    styleChange(value){
      this.classItem.param.classify_style = value;
      this.pubilcCom();
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    .diy-radio-group {
      min-height: 32px;
    }
    .picurl {
      width: 53px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .uploadImg {
      width: 50px;
      height: 100%;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      span {
        display: block;
        font-size: 24px;
        color: #f0f0f0;
      }
    }
    /deep/.el-table {
      background: #f0f0f0;
      .el-table--scrollable-x .el-table__body-wrapper {
        background: #fff !important;
      }
      .el-table__body-wrapper {
        background: #fff;
      }
      /deep/.el-table__body-wrapper.is-scrolling-left {
        background: #fff !important;
      }
    }
    .addNav {
      width: 100%;
      height: 32px;
      line-height: 30px;
      border: 1px dashed #ccc;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-top: 24px;
      cursor: pointer;
    }
  }
}
.check-color {
  .slider_text {
    width: 210px;
  }
  .check_color {
    min-width: 80px;
    height: 24px;
    background: #f0f0f0;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    border: none;
    outline: none;
    padding: 0 8px;
    margin-left: 18px;
    line-height: 24px;
  }
}
</style>