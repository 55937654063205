<template>
  <div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">关键词</div>
      <div class="diy-group-content">
        <el-input
          v-model="mining.param.keyword" @change="keyChange"
          placeholder="请输入关键词"
        ></el-input>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">标题</div>
      <div class="diy-group-content">
        <el-input
          v-model="mining.param.title" @change="titleInput"
          placeholder="请输入标题"
        ></el-input>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">文本</div>
      <div class="diy-group-content">
        <el-input
          v-model="mining.param.text" @change="describeInput"
          placeholder="请输入文本"
        ></el-input>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">商品角标</div>
      <div class="diy-group-content">
        <el-radio-group
          class="diy-radio-group d-flex align-items-center flex-wrap"
          v-model="mining.param.style" @change="radioChange"
        >
          <el-radio :label="0">无</el-radio>
          <el-radio :label="1">热销</el-radio>
          <el-radio :label="2">新品</el-radio>
          <el-radio :label="3" class="radioDis"
            >折扣
            <div v-if="mining.param.style == 3" class="discount">
              <el-input v-model="mining.param.discount"></el-input>
            </div>
          </el-radio>
          <el-radio :label="4">推荐</el-radio>
          <el-radio :label="5">自定义</el-radio>
          <div
            class="custom d-flex align-items-center"
            v-if="mining.param.style == 5"
          >
            <img
              v-if="mining.param.pic_url"
              :src="mining.param.pic_url"
              alt=""  @click.stop="custompic"
            />
            <div
              class="d-flex justify-content-center align-items-center uploadImage"
              v-if="!mining.param.pic_url" @click.stop="custompic"
            >
              <span>+</span>
            </div>
            <p class="custom_title">(推荐尺寸64*64)</p>
          </div>
        </el-radio-group>
        <el-table :data="mining.param.advertising" style="width: 100%">
          <el-table-column label="商品信息" min-width="320">
            <template slot-scope="scope">
              <div class="shoplister d-flex align-items-center">
                <div class="shopimg">
                  <img :src="scope.row.cover_pic" alt="" />
                </div>
                <div class="shoptext">
                  <h6>{{ scope.row.name }}</h6>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="价格" align="center" min-width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.price }}积分</div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope, 'advertising')"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < mining.param.advertising.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope, 'advertising')"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div
                class="handle"
                @click.stop="handleTable(scope, 'advertising')"
              >
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="addgood('advertising')"
        >
          <span>+</span>添加商品
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy_content">
        <el-table :data="mining.param.goodslist" style="width: 100%">
          <el-table-column label="商品信息" min-width="320">
            <template slot-scope="scope">
              <div class="shoplister d-flex align-items-center">
                <div class="shopimg">
                  <img :src="scope.row.cover_pic" alt="" />
                </div>
                <div class="shoptext">
                  <h6>{{ scope.row.name }}</h6>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="价格" align="center" min-width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.price }}积分</div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < mining.param.advertising.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div
                class="handle"
                @click.stop="handleTable(scope)"
              >
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="addgood('list')" v-if="mining.param.goodslist.length<4"
        >
          <span>+</span>添加商品
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "recommend",
  props: ["param"],
  data() {
    return {
      mining: this.param,
      tem_type: "",
    };
  },
  methods: {
    
    titleInput(value) {
      this.mining.param.title = value;
      this.pubilcCom();
    },
    describeInput(value) {
      this.mining.param.text = value;
      this.pubilcCom();
    },
    keyChange(value){
      this.mining.param.keyword = value;
      this.pubilcCom();
    },
    radioChange(val){
      var pic_url='';
      switch (val){
          case 1:
            pic_url=require("../../../assets/diy/rx.png");
          break;
          case 2:
            pic_url=require("../../../assets/diy/xp.png");
          break;
          case 4:
            pic_url=require("../../../assets/diy/tj.png");
          break;
          default:
            pic_url="";
          break;
      }
      this.mining.param.pic_url=pic_url;
      this.pubilcCom();
      this.$forceUpdate()
    },
    custompic(){
      this.$emit("change","img")
    },
    addgood(type) {
      this.tem_type = type;
      this.$emit("change", type);
    },
    // 往上挪一位
    moreUp(scope, type) {
      let index = scope.$index;
      if (type == "advertising") {
        var options = JSON.parse(
          JSON.stringify(this.mining.param.advertising[index - 1])
        );
        this.$set(
          this.mining.param.advertising,
          index - 1,
          this.mining.param.advertising[index]
        );
        this.$set(this.mining.param.advertising, index, options);
      } else {
        var options = JSON.parse(
          JSON.stringify(this.mining.param.goodslist[index - 1])
        );
        this.$set(
          this.mining.param.goodslist,
          index - 1,
          this.mining.param.goodslist[index]
        );
        this.$set(this.mining.param.goodslist, index, options);
      }

      this.pubilcCom();
    },
    // 往下挪一位
    moreDown(scope, type) {
      let index = scope.$index;
      if (type) {
        var options = JSON.parse(
          JSON.stringify(this.mining.param.advertising[index + 1])
        );
        this.$set(
          this.mining.param.advertising,
          index + 1,
          this.mining.param.advertising[index]
        );
        this.$set(this.mining.param.advertising, index, options);
      } else {
        var options = JSON.parse(
          JSON.stringify(this.mining.param.goodslist[index + 1])
        );
        this.$set(
          this.mining.param.goodslist,
          index + 1,
          this.mining.param.goodslist[index]
        );
        this.$set(this.mining.param.goodslist, index, options);
      }
      this.pubilcCom();
    },
    handleTable(scope, type = "") {
      let index = scope.$index;
      //console.log(type)
      if (type) {
        this.mining.param.advertising.splice(index, 1);
      } else {
        this.mining.param.goodslist.splice(index, 1);
      }
    },
    pubilcCom() {
      let data = this.mining;
      this.$emit("change", "", data);
    },
  },
};
</script>
<style lang="scss" scoped>


.shoplister {
    width: 100%;
}

.shopimg {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 12px;
}

.shopimg img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.shoptext {
    width: 100%;
    float: left;
    width: calc(100% - 62px);
}

.shoptext h6 {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #333;
    font-weight: 500;
    line-height: 22px;
    max-height: 44px;
}
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    /deep/.el-input {
      width: 280px;
      height: 32px;
      line-height: 32px;
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .diy-radio-group {
      min-height: 32px;
    }
  }
  .diy_content {
    width: 100%;
  }
}
.custom {
  margin-left: 10px;
  img {
    width: 32px;
    height: 32px;
  }
  .uploadImage {
    width: 32px;
    height: 32px;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    span {
      display: block;
      font-size: 24px;
      color: #f0f0f0;
    }
  }
  .custom_title {
    font-size: 12px;
    color: #666;
    margin-left: 10px;
    line-height: 32px;
  }
}
.radioDis {
  display: flex;
  align-items: center;
  /deep/.el-radio__label {
    display: flex;
    align-items: center;
  }
}
.discount {
  margin-left: 10px;
  width: 70px;
  height: 32px;
  line-height: 32px;
  /deep/.el-input {
    width: 70px !important;
    height: 32px;
    line-height: 32px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}
.addNav {
  width: 100%;
  height: 32px;
  line-height: 30px;
  border: 1px dashed #ccc;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
  cursor: pointer;
}
.handle{
  cursor: pointer;
  i{
    font-size: 20px;
  }
}
.dy_more {
  display: inline-block;
  width: 28px;
  height: 32px;
  cursor: pointer;
  vertical-align: sub;
  i {
    line-height: 32px;
  }
}
</style>