<template>
  <div class="tem_container">
    <template v-if="temPublic.type == 'channel'">
      <div class="mining_container">
        <div class="miningWrap" @click.stop="revamp(temPublic.type)">
          <div class="mining_head">
            <div class="mining_header">
              <p class="title">
                <span>{{
                  temPublic.param.keyword ? temPublic.param.keyword : "关键词"
                }}</span
                >{{ temPublic.param.title ? temPublic.param.title : "标题" }}
              </p>
              <p class="content">
                {{ temPublic.param.text ? temPublic.param.text : "文本" }}
              </p>
            </div>
            <div class="mining_more" v-if="temPublic.param.more_link">
              <p>更多</p>
              <img src="../../../assets/diy/more_right.png" alt="" />
            </div>
          </div>
          <div class="ming_wrap">
            <div class="swiper-container swiperming" ref="swiperming">
              <div class="swiper-wrapper">
                <template>
                  <div
                    class="ming_nav swiper-slide"
                    v-for="(item, index) in temPublic.param.list"
                    :key="index"
                  >
                    <img :src="item.pic_url" class="ming_img" alt="" />
                    <div class="ming_content">
                      <p>{{ item.title }}</p>
                      <p class="show-one-lines">{{ item.describe }}</p>
                    </div>
                  </div>
                </template>

                <template v-if="6 - temPublic.param.list.length > 0">
                  <div
                    class="ming_nav swiper-slide"
                    v-for="item in 6 - temPublic.param.list.length"
                  >
                    <img
                      src="../../../assets/diy/default.png"
                      class="ming_img"
                      alt=""
                    />
                    <div class="ming_content">
                      <p>模块名称</p>
                      <p class="show-one-lines">模快描述</p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="module-edit module-hover-show">
            <div class="module-handle d-flex flex-wrap">
              <div class="module-delete" @click.stop="delbannner('del')">
                <i class="el-icon-close"></i>
              </div>
              <div class="module-copy" @click.stop="delbannner('copy')">
                <i class="el-icon-document-copy"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex < temIndex.length - 1"
                @click.stop="delbannner('down')"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex > 0"
                @click.stop="delbannner('up')"
              >
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="temPublic.type == 'brand'">
      <div class="mining_container">
        <div class="miningWrap" @click.stop="revamp(temPublic.type)">
          <div class="mining_head">
            <div class="mining_header">
              <p class="title">
                <span>{{
                  temPublic.param.keyword ? temPublic.param.keyword : "关键词"
                }}</span
                >{{ temPublic.param.title ? temPublic.param.title : "标题" }}
              </p>
              <p class="content">
                {{ temPublic.param.text ? temPublic.param.text : "文本" }}
              </p>
            </div>
            <div class="mining_more" v-if="temPublic.param.more_link">
              <p>更多</p>
              <img src="../../../assets/diy/more_right.png" alt="" />
            </div>
          </div>
          <div class="brand_wrap">
            <div
              v-for="(item, index) in temPublic.param.list"
              :key="index"
              class="brand_li"
            >
              <img :src="item.pic_url" alt="" />
            </div>
            <template v-if="20 - temPublic.param.list.length > 0">
              <div
                v-for="item in 20 - temPublic.param.list.length"
                class="brand_li"
              >
                <img src="../../../assets/diy/default.png" alt="" />
              </div>
            </template>
          </div>

          <div class="module-edit module-hover-show">
            <div class="module-handle d-flex flex-wrap">
              <div class="module-delete" @click.stop="delbannner('del')">
                <i class="el-icon-close"></i>
              </div>
              <div class="module-copy" @click.stop="delbannner('copy')">
                <i class="el-icon-document-copy"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex < temIndex.length - 1"
                @click.stop="delbannner('down')"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex > 0"
                @click.stop="delbannner('up')"
              >
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="temPublic.type == 'new'">
      <div class="mining_container">
        <div class="miningWrap" @click.stop="revamp(temPublic.type)">
          <div class="mining_head">
            <div class="mining_header">
              <p class="title">
                {{ temPublic.param.title ? temPublic.param.title : "标题" }}
              </p>
              <p class="content">
                {{ temPublic.param.text ? temPublic.param.text : "文本" }}
              </p>
            </div>
            <div class="mining_more" v-if="temPublic.param.more_link">
              <p>更多</p>
              <img src="../../../assets/diy/more_right.png" alt="" />
            </div>
          </div>
          <div class="good_container">
            <div class="goods_wrap">
              <div class="swiper-container newgood" ref="newgood">
                <div class="swiper-wrapper">
                  <div
                    class="good_nav swiper-slide"
                    v-for="(item, index) in temPublic.param.goodslist"
                    :key="index"
                  >
                    <img :src="item.cover_pic" alt="" />
                    <p class="show-one-lines">{{ item.name }}</p>
                  </div>
                  <template v-if="5 - temPublic.param.goodslist.length > 0">
                    <div
                      class="good_nav swiper-slide"
                      v-for="item in 5 - temPublic.param.goodslist.length"
                    >
                      <img src="../../../assets/diy/default.png" alt="" />
                      <p class="show-one-lines">商品名称</p>
                    </div>
                  </template>
                </div>
                <div class="swiper-button-prev hot_prev" slot="button-prev">
                  <img src="../../../assets/diy/white_left.png" alt="" />
                </div>
                <div class="swiper-button-next hot_next" slot="button-next">
                  <img src="../../../assets/diy/white_right.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="module-edit module-hover-show">
            <div class="module-handle d-flex flex-wrap">
              <div class="module-delete" @click.stop="delbannner('del')">
                <i class="el-icon-close"></i>
              </div>
              <div class="module-copy" @click.stop="delbannner('copy')">
                <i class="el-icon-document-copy"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex < temIndex.length - 1"
                @click.stop="delbannner('down')"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex > 0"
                @click.stop="delbannner('up')"
              >
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 好物推荐 -->
    <template v-if="temPublic.type == 'recommend'">
      <div class="reco_container">
        <div class="recommWrap" @click.stop="revamp(temPublic.type)">
          <div class="mining_head">
            <div class="mining_header">
              <p class="title">
                <span>{{
                  temPublic.param.keyword ? temPublic.param.keyword : "关键词"
                }}</span
                >{{ temPublic.param.title ? temPublic.param.title : "标题" }}
              </p>
              <p class="content">
                {{ temPublic.param.text ? temPublic.param.text : "文本" }}
              </p>
            </div>
          </div>
          <div class="interesting">
            <div class="swiper-container interSwiper" ref="interSwiper">
              <div class="swiper-wrapper">
                <div
                  class="inter_nav swiper-slide"
                  v-for="(item, index) in temPublic.param.advertising"
                  :key="index"
                >
                  <div class="inter_wrap">
                    <div class="inter_img">
                      <img :src="item.cover_pic" alt="" />
                      <template v-if="temPublic.param.style != 0">
                        <div
                          class="inter_style"
                          v-if="temPublic.param.style != 3"
                        >
                          <img :src="temPublic.param.pic_url" alt="" />
                        </div>
                        <template v-else>
                          <div class="discount">
                            {{ temPublic.param.discount }}折
                          </div>
                        </template>
                      </template>
                    </div>
                    <div class="inter_detail">
                      <div class="inter_title show-one-lines">
                        {{ item.name }}
                      </div>
                      <div class="inter_price">{{ item.price }}积分</div>
                    </div>
                  </div>
                </div>
                <template v-if="5 - temPublic.param.advertising.length > 0">
                  <div
                    class="inter_nav swiper-slide"
                    v-for="item in 5 - temPublic.param.advertising.length"
                  >
                    <div class="inter_wrap">
                      <div class="inter_img">
                        <img src="../../../assets/diy/default.png" alt="" />
                      </div>
                      <div class="inter_detail">
                        <div class="inter_title show-one-lines">商品名称</div>
                        <div class="inter_price">99.99积分</div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="swiper-pagination bannerpage seckill-p"></div>
            </div>
          </div>
          <div class="good_thing">
            <div
              class="good_single"
              v-for="(item, index) in temPublic.param.goodslist"
              :key="index"
            >
              <img :src="item.cover_pic" class="good_img" alt="" />
              <div class="good_title show-two-lines">{{ item.name }}</div>
              <div class="good_price">{{ item.price }}积分</div>
            </div>
            <template v-if="4 - temPublic.param.goodslist.length > 0">
              <div
                class="good_single"
                v-for="(item, index) in 4 - temPublic.param.goodslist.length"
                :key="index"
              >
                <img
                  src="../../../assets/diy/default.png"
                  class="good_img"
                  alt=""
                />
                <div class="good_title show-two-lines">商品名称</div>
                <div class="good_price">99.99积分</div>
              </div>
            </template>
          </div>
          <div class="module-edit module-hover-show">
            <div class="module-handle d-flex flex-wrap">
              <div class="module-delete" @click.stop="delbannner('del')">
                <i class="el-icon-close"></i>
              </div>
              <div class="module-copy" @click.stop="delbannner('copy')">
                <i class="el-icon-document-copy"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex < temIndex.length - 1"
                @click.stop="delbannner('down')"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex > 0"
                @click.stop="delbannner('up')"
              >
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 个性商品 -->
    <template v-if="temPublic.type == 'character'">
      <div class="character" @click.stop="revamp(temPublic.type)">
        <div class="mining_head">
          <div class="mining_header">
            <p class="title">
              {{ temPublic.param.title ? temPublic.param.title : "标题" }}
            </p>
            <p class="content">
              {{ temPublic.param.text ? temPublic.param.text : "文本" }}
            </p>
          </div>
        </div>
        <div class="selfdom">
          <div
            v-for="(item, index) in temPublic.param.goodslist"
            :key="index"
            class="selfdom_li"
          >
            <div class="goodImg">
              <img :src="item.cover_pic" alt="" />
            </div>
            <div class="goodDeatil">
              <div class="show-one-lines goods_title">
                {{ item.name }}
              </div>
              <div class="goods_peice">{{ item.price }}积分</div>
            </div>
          </div>
          <template v-if="5 - temPublic.param.goodslist.length > 0">
            <div
              v-for="item in 5 - temPublic.param.goodslist.length"
              class="selfdom_li"
            >
              <div class="goodImg">
                <img src="../../../assets/diy/default.png" alt="" />
              </div>
              <div class="goodDeatil">
                <div class="show-one-lines goods_title">商品名称</div>
                <div class="goods_peice">99.99积分</div>
              </div>
            </div>
          </template>
        </div>

        <div class="module-edit module-hover-show">
          <div class="module-handle d-flex flex-wrap">
            <div class="module-delete" @click.stop="delbannner('del')">
              <i class="el-icon-close"></i>
            </div>
            <div class="module-copy" @click.stop="delbannner('copy')">
              <i class="el-icon-document-copy"></i>
            </div>
            <div
              class="module-down"
              v-if="sortIndex < temIndex.length - 1"
              @click.stop="delbannner('down')"
            >
              <i class="el-icon-arrow-down"></i>
            </div>
            <div
              class="module-down"
              v-if="sortIndex > 0"
              @click.stop="delbannner('up')"
            >
              <i class="el-icon-arrow-up"></i>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 精选好店 商品热销榜 -->
    <template v-if="temPublic.type == 'hot'">
      <div class="hotWrap">
        <div class="hot_wrap">
          <div class="hot_title">{{ temPublic.param.title }}</div>
          <div class="hotAllClass">
            <div class="hot_nav">
              <!-- 分类 -->
              <div class="swiper-container hotclassify">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide hot_class"
                    v-for="(item, index) in temPublic.param.cat_list"
                    :key="index"
                  >
                    <div class="hotify" :class="index == 0 ? 'active' : ''">
                      {{ item.name }}
                    </div>
                  </div>
                  <template v-if="8 - temPublic.param.cat_list.length > 0">
                    <div
                      class="swiper-slide hot_class"
                      v-for="item in 8 - temPublic.param.cat_list.length"
                    >
                      <div class="hotify" :class="item == 0 ? 'active' : ''">
                        分类名称
                      </div>
                    </div>
                  </template>
                </div>
                <div class="swiper-button-prev hot_prev" slot="button-prev">
                  <img src="../../../assets/diy/swiper_left.png" alt="" />
                </div>
                <div class="swiper-button-next hot_next" slot="button-next">
                  <img src="../../../assets/diy/swiper_right.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="hot_good">
            <div class="hotGood">
              <template>
                <div class="swiper-container hotgood">
                  <div class="swiper-wrapper">
                    <template v-for="(i, b) in temPublic.param.cat_list">
                      <template v-if="i && b == 0">
                        <div
                          class="swiper-slide hot_shop"
                          v-for="(item, index) in i.goodslist"
                          :key="index"
                        >
                          <div class="merchandise">
                            <div class="good_img">
                              <img :src="item.cover_pic" alt="" />
                              <template v-if="index < 4">
                                <img
                                  v-if="index == 0"
                                  src="../../../assets/diy/one.svg"
                                  alt=""
                                  class="good_style"
                                />
                                <img
                                  v-if="index == 1"
                                  src="../../../assets/diy/two.svg"
                                  alt=""
                                  class="good_style"
                                />
                                <img
                                  v-if="index == 2"
                                  src="../../../assets/diy/three.svg"
                                  alt=""
                                  class="good_style"
                                />
                                <img
                                  v-if="index == 3"
                                  src="../../../assets/diy/four.svg"
                                  alt=""
                                  class="good_style"
                                />
                              </template>
                            </div>
                            <div class="good_title show-two-lines">
                              {{ item.name }}
                            </div>
                            <div class="good_price">{{ item.price }}积分</div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                  <div class="swiper-pagination bannerpage seckill-p"></div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="module-edit module-hover-show"
            @click.stop="revamp(temPublic.type)"
          >
            <div class="module-handle d-flex flex-wrap">
              <div class="module-delete" @click.stop="delbannner('del')">
                <i class="el-icon-close"></i>
              </div>
              <div class="module-copy" @click.stop="delbannner('copy')">
                <i class="el-icon-document-copy"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex < temIndex.length - 1"
                @click.stop="delbannner('down')"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex > 0"
                @click.stop="delbannner('up')"
              >
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 好物 -->
    <template v-if="temPublic.type == 'fine'">
      <div class="fine_container">
        <div class="fine_wrap">
          <div class="mining_head">
            <div class="mining_header">
              <p class="title">
                {{ temPublic.param.title ? temPublic.param.title : "标题" }}
              </p>
              <p class="content">
                {{ temPublic.param.text ? temPublic.param.text : "文本" }}
              </p>
            </div>
          </div>
          <div class="fine_nav" v-if="temPublic.param.pic_url">
            <img :src="temPublic.param.pic_url" alt="" />
          </div>
          <div class="fine_nav" v-else>
            <img src="../../../assets/diy/default.png" alt="" />
          </div>
          <div class="good_thing">
            <div
              class="good_single"
              v-for="(item, index) in temPublic.param.goodslist"
              :key="index"
            >
              <img :src="item.cover_pic" class="good_img" alt="" />
              <div class="good_title show-two-lines">{{ item.name }}</div>
              <div class="good_price">{{ item.price }}积分</div>
            </div>
            <template v-if="4 - temPublic.param.goodslist.length > 0">
              <div
                class="good_single"
                v-for="item in 4 - temPublic.param.goodslist.length"
              >
                <img
                  src="../../../assets/diy/default.png"
                  class="good_img"
                  alt=""
                />
                <div class="good_title show-two-lines">商品名称</div>
                <div class="good_price">99.99积分</div>
              </div>
            </template>
          </div>
          <div
            class="module-edit module-hover-show"
            @click.stop="revamp(temPublic.type)"
          >
            <div class="module-handle d-flex flex-wrap">
              <div class="module-delete" @click.stop="delbannner('del')">
                <i class="el-icon-close"></i>
              </div>
              <div class="module-copy" @click.stop="delbannner('copy')">
                <i class="el-icon-document-copy"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex < temIndex.length - 1"
                @click.stop="delbannner('down')"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex > 0"
                @click.stop="delbannner('up')"
              >
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 楼层 -->
    <template v-if="temPublic.type == 'floor'">
      <div class="link_container">
        <div class="like_wrap">
          <div class="floor_head">
            <div class="floor_headline">
              <p class="title">
                {{ temPublic.param.title }}
              </p>
              <p class="content">
                {{ temPublic.param.text }}
              </p>
            </div>
            <div class="floor_class">
              <div
                class="dustbin show-one-lines"
                v-for="(item, index) in temPublic.param.cat_list"
                :key="index"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top"
                >
                  <span>{{ item.name }}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="floor_article">
            <div class="floor_hot">
              <div class="hot_head"><span>热销</span>TOP5</div>
              <div class="hot_good">
                <div
                  class="goods_hot"
                  v-for="(item, index) in temPublic.param.hot_list"
                  :key="index"
                >
                  <div class="hot_img">
                    <img :src="item.cover_pic" alt="" />
                  </div>
                  <div class="hot_detail">
                    <div class="hot_title show-two-lines">{{ item.name }}</div>
                    <div class="hot_price">{{ item.price }}积分</div>
                  </div>
                </div>
                <template v-if="5 - temPublic.param.hot_list.length > 0">
                  <div
                    class="goods_hot"
                    v-for="item in 5 - temPublic.param.hot_list.length"
                  >
                    <div class="hot_img">
                      <img src="../../../assets/diy/default.png" alt="" />
                    </div>
                    <div class="hot_detail">
                      <div class="hot_title show-two-lines">商品名称</div>
                      <div class="hot_price">99.99积分</div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="hot_more" v-if="temPublic.param.hot_url">
                查看更多
                <img src="../../../assets/diy/swiper_right.png" alt="" />
              </div>
            </div>
            <div class="floor_shop">
              <template v-for="(i, b) in temPublic.param.cat_list">
                <template v-if="i && b == 0">
                  <div
                    class="shop_single"
                    v-for="(item, index) in temPublic.param.cat_list[0]
                      .goodslist"
                    :key="index"
                  >
                    <div class="single_img">
                      <img :src="item.cover_pic" alt="" />
                    </div>
                    <div class="single_title show-two-lines">
                      {{ item.name }}
                    </div>
                    <div class="single_price">{{ item.price }}积分</div>
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div
            class="module-edit module-hover-show"
            @click.stop="revamp(temPublic.type)"
          >
            <div class="module-handle d-flex flex-wrap">
              <div class="module-delete" @click.stop="delbannner('del')">
                <i class="el-icon-close"></i>
              </div>
              <div class="module-copy" @click.stop="delbannner('copy')">
                <i class="el-icon-document-copy"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex < temIndex.length - 1"
                @click.stop="delbannner('down')"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex > 0"
                @click.stop="delbannner('up')"
              >
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 猜你喜欢 -->
    <template v-if="temPublic.type == 'like'">
      <div class="floor_wrap">
       
        <div class="floor_container" @click.stop="revamp(temPublic.type)">
          <div class="floor_head">
            <div class="floor_left">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="floor_title">{{ temPublic.param.title }}</div>
            <div class="floor_right">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="like_nav">
            <div class="floor_nav">
              <div
                class="class_li"
                v-for="(item, index) in temPublic.param.cat_list"
                :key="index"
                :class="[floorclass == index ? 'active' : '']"
              >
                {{ item.name }}
              </div>
              <template v-if="5 - temPublic.param.cat_list.length > 0">
                <div
                  class="class_li"
                  v-for="item in 5 - temPublic.param.cat_list.length"
                  :class="[floorclass == item ? 'active' : '']"
                >
                  分类名称
                </div>
              </template>
            </div>
          </div>

          <div class="floor_content">
            <template v-for="(i, b) in temPublic.param.cat_list">
              <template v-if="i && b == 0">
                <div
                  class="floor_good"
                  v-for="(item, index) in i.goodslist"
                  :key="index"
                >
                  <div class="floorgood">
                    <div class="good_img">
                      <img :src="item.cover_pic" alt="" />
                    </div>
                    <div class="good_title show-two-lines">
                      {{ item.name }}
                    </div>
                    <div class="good_price">{{ item.price }}积分</div>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <div class="module-edit module-hover-show">
            <div class="module-handle d-flex flex-wrap">
              <div class="module-delete" @click.stop="delbannner('del')">
                <i class="el-icon-close"></i>
              </div>
              <div class="module-copy" @click.stop="delbannner('copy')">
                <i class="el-icon-document-copy"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex < temIndex.length - 1"
                @click.stop="delbannner('down')"
              >
                <i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="module-down"
                v-if="sortIndex > 0"
                @click.stop="delbannner('up')"
              >
                <i class="el-icon-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { Swiper } from "swiper";
export default {
  props: ["param","pubicIndex"],
  name: "temPublic",
  data() {
    return {
      temPublic: this.param,
      temIndex: [],
      floorclass: 2,
    };
  },
  computed: {
    templatePublic() {
      this.temIndex = this.$parent.temp_list;
      let arr = [];
      if (this.temPublic.type == "recommend") {
        arr = this.temPublic.param.advertising;
      } else if (this.temPublic.type == "hot") {
        arr = this.temPublic.param.cat_list;
      } else if (this.temPublic.type == "new") {
        arr = this.temPublic.param.goodslist;
      } else if (this.temPublic.type == "channel") {
        arr = this.temPublic.param.list;
      }
      return arr;
    },
    sortIndex() {
      let index = this.temPublic.index;
      return index;
    },
    hotlist() {
      var val = [];
      if (this.temPublic.type == "hot") {
        // //console.log(6565);
        if (
          this.temPublic.param.cat_list.length &&
          this.temPublic.param.cat_list[0]
        ) {
          val = this.temPublic.param.cat_list[0].goodslist;
        }
      }
      return val;
    },
  },

  watch: {
    templatePublic: {
      handler(newVal, oldVal) {
        // //console.log(newVal, oldVal);
        if (newVal.length) {
          this.resswiper();
        }
      },
      deep: true,
    },
    sortIndex: {
      handler(newVal, oldVal) {
        // //console.log(newVal, oldVal);
      },
      immediate: true,
      deep: true,
    },
    hotlist: {
      handler(newVal, oldVal) {
        // //console.log(newVal, oldVal);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    resswiper() {
      let that = this;
      if (this.temPublic.type == "recommend") {
        this.$nextTick(() => {
          var interSwiper = new Swiper(".interSwiper", {
            autoplay: true,
            loop: true,
            speed: 1000,
            direction: "horizontal",
            preventClicks: false,
            paginationClickable: true,
            observer: true,
            observeParents: true,
            pagination: {
              el: ".bannerpage",
              dynamicBullets: true,
              dynamicMainBullets: 2,
            },
          });
        });
      } else if (this.temPublic.type == "hot") {
        this.$nextTick(() => {
          var hotclassify = new Swiper(".hotclassify", {
            autoplay: false,
            slidesPerView: "auto",
            loop: false,
            speed: 1000,
            direction: "horizontal",
            preventClicks: false,
            paginationClickable: true,
            pagination: ".new-p",
            observer: true,
            observeParents: true,
            navigation: {
              nextEl: ".hot_next",
              prevEl: ".hot_prev",
            },
          });
        });
        this.$nextTick(() => {
          var goodswiper = new Swiper(".hotgood", {
            autoplay: true,
            slidesPerView: 4,
            loop: true,
            speed: 1000,
            direction: "horizontal",
            preventClicks: false,
            paginationClickable: true,
            observer: true,
            observeParents: true,
            pagination: {
              el: ".bannerpage",
              dynamicBullets: true,
              dynamicMainBullets: 4,
            },
          });
        });
      } else if (this.temPublic.type == "new") {
        this.$nextTick(() => {
          var goodswiper = new Swiper(".newgood", {
            autoplay: true,
            slidesPerView: "auto",
            loop: true,
            speed: 1000,
            direction: "horizontal",
            preventClicks: false,
            paginationClickable: true,
            observer: true,
            observeParents: true,
            navigation: {
              nextEl: ".hot_next",
              prevEl: ".hot_prev",
            },
          });
        });
      } else if (this.temPublic.type == "channel") {
        var mySwiper = new Swiper(".swiperming", {
          autoplay: true,
          slidesPerView: "auto",
          loop: true,
          speed: 1000,
          direction: "horizontal",
          preventClicks: false,
          paginationClickable: true,
          observer: true,
          observeParents: true,
        });
      }
    },
    revamp(type) {
      let data = {
        type: type,
        index: this.pubicIndex,
      };
      this.$emit("change", data);
    },
    delbannner(type) {
      let data = {
        type: type,
        index: this.pubicIndex,
        id: this.temPublic.id,
      };
      this.$emit("navchange", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.tem_container {
  width: 100%;
  height: 100%;
  background: #f2f4f7;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  .mining_container {
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    .miningWrap {
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 3px;
      padding-top: 24px;
      padding-bottom: 20px;
      position: relative;
      &:hover {
        .module-edit {
          visibility: visible;
          opacity: 1;
        }
      }
      .mining_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 24px;
        .mining_header {
          display: flex;
          align-items: flex-end;
          .title {
            font-size: 24px;
            font-weight: 600;
            color: #333333;
            line-height: 32px;
            span {
              color: #ed2a20;
            }
          }
          .content {
            margin-left: 18px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
          }
        }
        .mining_more {
          display: flex;
          align-items: center;
          p {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            margin-right: 6px;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .ming_wrap {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .ming_nav {
          width: 222px;
          height: 79px;
          border-radius: 3px;
          border: 1px solid #f5f5f5;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          transition: 0.5s;
          -moz-transition: 0.5s;
          -webkit-transition: 0.5s;
          -o-transition: 0.5s;
          img {
            width: 44px;
            height: 44px;
            object-fit: cover;
          }
          .ming_content {
            margin-left: 7px;
            max-width: 140px;
            p:first-child {
              font-size: 17px;
              font-weight: 500;
              color: #333333;
              line-height: 28px;
            }
            p:last-child {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
            }
          }
        }
      }
      .brand_wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;
        // padding: 0 20px;
        padding-left: 24px;
        padding-right: 16px;
        .brand_li {
          width: 133px;
          height: 64px;
          border-radius: 3px;
          border: 1px solid #f5f5f5;
          margin-right: 10px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            margin: 0 auto;
          }
        }
      }
      .good_container {
        position: relative;
        // width: 100%;
        // padding: 0 24px;
        // margin-top: 32px;
        margin-top: 16px;
      }
      .goods_wrap {
        display: flex;
        align-items: center;
        margin: 0 24px;
        width: 1152px;
        overflow: hidden;
        padding: 0;
        // margin: 0 24px;
        // width: calc( 100% - 48px );
        // width: 100%;
        .swiperming {
          width: 100%;
        }
        .hot_prev {
          width: 20px;
          height: 64px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 0px 6px 6px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 12px;
            height: 20px;
          }
          &::after {
            content: "";
          }
        }
        .hot_next {
          width: 20px;
          height: 64px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 0px 6px 6px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 12px;
            height: 20px;
          }
          &::after {
            content: "";
          }
        }

        .good_nav {
          // margin-right: 32px;
          text-align: center;
          width: 160px;
          padding: 16px;
          img {
            width: 160px;
            height: 160px;
            object-fit: cover;
            margin: 0 auto;
          }
          p {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-top: 10px;
            width: 160px;
          }
        }
      }
    }
  }
}
.module-edit {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  justify-content: flex-end;
  .module-handle {
    width: 50px;
    height: 44px;

    > div {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #000000;
      border-radius: 2px;
      background-position: center center;
      background-repeat: no-repeat;
      transition: all 0.3s;
      margin: 0 4px 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 15px;
        color: #ccc;
      }
    }
  }
}
.module-hover-show {
  top: 0;
}
// 好物推荐
.reco_container {
  margin-top: 20px;
}
.recommWrap {
  width: 392px;
  height: 544px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 3px;
  position: relative;
  .mining_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px;
    padding-top: 16px;
    .mining_header {
      display: flex;
      align-items: flex-end;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
        line-height: 32px;
        span {
          color: #ed2a20;
        }
      }
      .content {
        margin-left: 18px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }
  }
  &:hover {
    .module-edit {
      visibility: visible;
      opacity: 1;
    }
  }
  .interesting {
    margin: 0 auto;
    margin-top: 16px;
    width: 352px;
    height: 120px;
    border-radius: 3px;
    flex-shrink: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    .inter_nav {
      width: 352px;
      height: 120px;

      .inter_wrap {
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #ffdbd9 0%, #ffffff 100%);
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        .inter_img {
          width: 80px;
          height: 80px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .inter_style {
            position: absolute;
            top: 0;
            left: 0;
            width: 36px;
            height: 36px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .discount {
            position: absolute;
            top: 0;
            left: 0;
            width: 56px;
            height: 26px;
            background: linear-gradient(90deg, #feb422 0%, #ffd565 100%);
            border-radius: 0px 0px 14px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
        }
        .inter_detail {
          width: 216px;
          margin-left: 12px;
          .inter_title {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
          .inter_price {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 500;
            color: #ff0e25;
            line-height: 16px;
          }
        }
      }
    }
    /deep/.swiper-pagination-bullet {
      width: 16px;
      height: 2px;
      background: #d8d8d8 !important;
    }
    /deep/.swiper-pagination-bullet-active {
      background: #ed2a20 !important;
    }
  }
  .good_thing {
    // width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
    padding-bottom: 26px;
    justify-content: space-between;
    .good_single {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 164px;
      .good_img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
      .good_title {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        height: 40px;
        text-align: center;
        width: 100%;
      }
      .good_price {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 500;
        color: #ff0e25;
        line-height: 16px;
      }
    }
  }
}
// 个性商品
.character {
  width: 392px;
  height: 544px;
  background: #ffffff;
  border-radius: 3px;
  margin-top: 20px;
  // padding: 16px 0;
  .mining_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px;
    padding-top: 16px;
    .mining_header {
      display: flex;
      align-items: flex-end;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
        line-height: 32px;
        span {
          color: #ed2a20;
        }
      }
      .content {
        margin-left: 18px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }
    .mining_more {
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        margin-right: 6px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .selfdom {
    margin: 0 20px;
    max-height: 456px;
    min-height: 456px;
    // overflow-y: auto;
    .selfdom_li {
      margin-top: 16px;
      display: flex;
      align-items: center;
      .goodImg {
        width: 80px;
        height: 80px;
        margin-right: 16px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .goodDeatil {
        width: 256px;
        .goods_title {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        .goods_peice {
          margin-top: 16px;
          font-size: 16px;
          font-weight: 500;
          color: #ff0e25;
          line-height: 16px;
        }
      }
    }
  }
  &:hover {
    .module-edit {
      visibility: visible;
      opacity: 1;
    }
  }
}
// 商品热销
.hotWrap {
  width: 594px;
  height: 368px;
  background: #ffffff;
  border-radius: 3px;
  margin-top: 20px;
  .hot_wrap {
    padding-top: 24px;
    position: relative;
    padding-bottom: 26px;
    height: calc(100% - 50px);
    .hot_title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 32px;
      margin: 0 26px;
    }
    .hotAllClass {
      position: relative;
      // width: 100%;
      margin-right: 10px;
    }
    .hot_nav {
      display: flex;
      align-items: center;
      margin-left: 26px;
      margin-top: 12px;
      margin-right: 77px;
      overflow: hidden;
      padding: 0;
      flex-shrink: 0;
      .hotclassify {
        width: calc(100% - 26px);
        // position: relative;
        /deep/.swiper-container {
          --swiper-navigation-size: 0;
        }

        .hot_class {
          width: auto;
        }
        .hotify {
          height: 28px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
          margin-right: 24px;
          &:hover {
            font-size: 14px;
            font-weight: 500;
            color: #ed2a20;
            line-height: 28px;
          }
        }
        .hotify.active {
          font-size: 14px;
          font-weight: 500;
          color: #ed2a20;
          line-height: 28px;
        }
        .hot_prev {
          position: absolute;
          right: 28px;
          left: 512px;
          &::after {
            content: "";
          }
        }
        .hot_next {
          &:after {
            content: "";
          }
        }
      }
    }
    .hot_good {
      position: relative;
      margin-top: 24px;
      padding-bottom: 36px;
      .hotGood {
        margin: 0 26px;
        padding: 0;
        overflow: hidden;
        flex-shrink: 0;
        /deep/.swiper-pagination-bullet {
          width: 16px;
          height: 2px;
          background: #d8d8d8 !important;
        }
        /deep/.swiper-pagination-bullet-active {
          background: #ed2a20 !important;
        }
        .bannerpage {
          bottom: 27px;
        }
      }
      .hot_shop {
        width: 136px;
        height: 216px;
      }
      .merchandise {
        // padding: 12px;
        // padding-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 136px;
        height: 216px;

        .good_img {
          width: 112px;
          height: 112px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .good_style {
            width: 27px;
            height: 32px;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
        .good_title {
          text-align: center;
          width: 112px;
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          margin-top: 12px;
        }
        .good_price {
          font-size: 16px;
          text-align: center;
          margin-top: 8px;
          font-weight: 500;
          color: #ff0e25;
          line-height: 16px;
        }
      }
    }
  }
  &:hover {
    .module-edit {
      visibility: visible;
      opacity: 1;
    }
  }
}
// 好物推荐
.fine_container {
  margin-top: 20px;
  width: 399px;
  height: 544px;
  .fine_wrap {
    width: 100%;
    height: 100%;
    position: relative;
    background: #fff;
    .mining_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 24px;
      padding-top: 16px;
      .mining_header {
        display: flex;
        align-items: flex-end;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: #333333;
          line-height: 32px;
          span {
            color: #ed2a20;
          }
        }
        .content {
          margin-left: 18px;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
      .mining_more {
        display: flex;
        align-items: center;
        p {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          margin-right: 6px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .fine_nav {
      width: 352px;
      height: 120px;
      margin: 0 auto;
      margin-top: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .good_thing {
      // width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 20px;
      padding-bottom: 26px;
      justify-content: space-between;
      .good_single {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 164px;
        .good_img {
          width: 80px;
          height: 80px;
          object-fit: cover;
        }
        .good_title {
          margin-top: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          height: 40px;
          text-align: center;
          width: 100%;
        }
        .good_price {
          margin-top: 8px;
          font-size: 16px;
          font-weight: 500;
          color: #ff0e25;
          line-height: 16px;
        }
      }
    }

    &:hover {
      .module-edit {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
// 楼层
.link_container {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  .like_wrap {
    position: relative;
    width: 100%;
    height: 682px;
    background: #fff;
    .floor_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 28px;
      margin-right: 24px;
      padding-top: 20px;
      .floor_headline {
        display: flex;
        align-items: flex-end;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: #333333;
          line-height: 32px;
          span {
            color: #ed2a20;
          }
        }
        .content {
          margin-left: 18px;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
      .floor_class {
        display: flex;
        align-items: center;
        .dustbin {
          margin-right: 32px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
          max-width: 100px;
        }
      }
    }
    .floor_article {
      display: flex;
      align-items: center;
      margin: 0 26px;
      margin-top: 24px;
      padding-bottom: 16px;
      .floor_hot {
        width: 250px;
        height: 590px;
        background: linear-gradient(180deg, #fbd7b6 0%, #ffffff 100%);
        border-radius: 8px;
        position: relative;
        .hot_head {
          margin-top: 19px;
          margin-left: 16px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 32px;
          span {
            color: #ed2a20;
            margin-right: 3px;
          }
        }
        .hot_good {
          padding-top: 8px;
          margin: 0 16px;
          .goods_hot {
            width: 218px;
            height: 86px;
            background: #ffffff;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8px;
            .hot_img {
              width: 70px;
              height: 70px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .hot_style {
                width: 27px;
                height: 32px;
                position: absolute;
                top: 0;
                left: 0;
                -o-object-fit: cover;
                object-fit: cover;
              }
            }
            .hot_detail {
              margin-left: 8px;
              .hot_title {
                width: 124px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                line-height: 16px;
              }
              .hot_price {
                margin-top: 13px;
                font-size: 16px;
                font-weight: 500;
                color: #ff0e25;
                line-height: 16px;
              }
            }
          }
        }
        .hot_more {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          position: absolute;
          bottom: 17px;
          width: 100%;
          img {
            width: 16px;
            height: 16px;
            object-fit: cover;
            margin-left: 8px;
          }
        }
      }
      .floor_shop {
        margin-left: 10px;
        flex: 1;
        display: flex;
        // align-items: center;
        flex-wrap: wrap;
        height: 590px;
        .shop_single {
          padding-top: 16px;
          padding-bottom: 14px;
          padding-left: 13px;
          padding-right: 13px;
          .single_img {
            width: 171px;
            height: 171px;
            margin: 0 auto;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .single_title {
            width: 196px;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin-top: 16px;
            text-align: center;
          }
          .single_price {
            margin-top: 15px;
            font-size: 18px;
            font-weight: 500;
            color: #ff0e25;
            line-height: 18px;
            text-align: center;
          }
        }
      }
    }
    &:hover {
      .module-edit {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

// 猜你喜欢
.floor_wrap {
  width: 1200px;
  margin: 0 auto;
  margin-top: 56px;
  .floor_container {
    width: 100%;
    position: relative;
    &:hover {
      .module-edit {
        visibility: visible;
        opacity: 1;
      }
    }
    .floor_head {
      display: flex;
      justify-content: center;
      align-items: center;
      .floor_left {
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        div:first-child {
          width: 53px;
          height: 3px;
          background: linear-gradient(270deg, #f7cb43 0%, #ed2a20 100%);
          border-radius: 2px;
        }
        div:nth-child(2) {
          margin-top: 6px;
          width: 64px;
          height: 3px;
          background: linear-gradient(270deg, #f7cb43 0%, #ed2a20 100%);
          border-radius: 2px;
        }
        div:last-child {
          width: 29px;
          height: 3px;
          background: linear-gradient(270deg, #f7cb43 0%, #ed2a20 100%);
          border-radius: 2px;
          margin-top: 6px;
        }
      }
      .floor_title {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
        line-height: 32px;
      }
      .floor_right {
        margin-left: 16px;
        div:first-child {
          width: 53px;
          height: 3px;
          background: linear-gradient(270deg, #f7cb43 0%, #ed2a20 100%);
          border-radius: 2px;
        }
        div:nth-child(2) {
          margin-top: 6px;
          width: 64px;
          height: 3px;
          background: linear-gradient(270deg, #f7cb43 0%, #ed2a20 100%);
          border-radius: 2px;
        }
        div:last-child {
          width: 29px;
          height: 3px;
          background: linear-gradient(270deg, #f7cb43 0%, #ed2a20 100%);
          border-radius: 2px;
          margin-top: 6px;
        }
      }
    }
    .like_nav {
      padding: 9px 39px;
      margin-top: 32px;
      background: #ffffff;
      border-radius: 3px;
    }
    .floor_nav {
      display: flex;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      .class_li {
        padding: 3px 15px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
        margin: 0 10px;
        min-width: 72px;
        flex-shrink: 0;
      }
      .class_li.active {
        background: #ed2a20;
        border-radius: 19px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
      }
    }
    .floor_nav::-webkit-scrollbar-thumb {
      background: transparent;
    }
    .floor_nav::-webkit-scrollbar-track-piece {
      background: transparent;
    }
    .floor_content {
      padding: 24px 26px;
      background: #fff;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 16px;
      .floor_good {
        border-right: solid 1px #ededed;
        border-top: solid 1px #ededed;
        // padding: 24px 2px;
        padding-bottom: 24px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .floorgood {
          // margin: 16px 14px 26px;
          margin-top: 16px;
          margin-left: 15px;
          margin-right: 15px;
          margin-bottom: 14px;
          .good_img {
            width: 171px;
            height: 171px;
            object-fit: cover;
            margin: 0 auto;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .good_title {
            margin-top: 16px;
            width: 196px;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            text-align: center;
          }
          .good_price {
            margin-top: 15px;
            font-size: 18px;
            font-weight: 500;
            color: #ff0e25;
            line-height: 18px;
            text-align: center;
          }
        }
      }
      .floor_good:first-child {
        border-top: none;
      }
      .floor_good:nth-child(2) {
        border-top: none;
      }
      .floor_good:nth-child(3) {
        border-top: none;
      }
      .floor_good:nth-child(4) {
        border-top: none;
      }
      .floor_good:nth-child(5) {
        border-top: none;
        border-right: none;
      }
      .floor_good:nth-child(5n) {
        border-right: none;
      }
    }
  }
}
</style>