<template>
  <div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">标题</div>
      <div class="diy-group-content">
        <el-input
          v-model="fineSeatail.param.title"
          placeholder="请输入标题" @change="titleInput"
        ></el-input>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">文本</div>
      <div class="diy-group-content">
        <el-input
          v-model="fineSeatail.param.text" @change="describeInput"
          placeholder="请输入文本"
        ></el-input>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">广告位</div>
      <div class="diy-group-content d-flex align-items-center">
        <div class="advertising" @click.stop="board('img')">
          <img
            :src="fineSeatail.param.pic_url"
            v-if="fineSeatail.param.pic_url"
            class="uploadImg"
            alt=""
          />
          <div
            class="upload_img d-flex justify-content-center align-items-center"
            v-else
          >
            <span>+</span>
          </div>
        </div>
        <div class="advertising_toast">(推荐尺寸347*167)</div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex"></div>
      <div class="diy-group-content d-flex align-items-center">
        <div class="title">
          <el-input
            v-model="fineSeatail.param.page_url"
            readonly
            @click.native="board('link')"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-link"
              style="color: #4877fb; font-size: 16px; line-height: 32px"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex"></div>
      <div class="diy_content">
        <el-table :data="fineSeatail.param.goodslist" style="width: 100%">
          <el-table-column label="商品信息" min-width="320">
            <template slot-scope="scope">
              <div class="shoplister d-flex align-items-center">
                <div class="shopimg">
                  <img :src="scope.row.cover_pic" alt="" />
                </div>
                <div class="shoptext">
                  <h6>{{ scope.row.name }}</h6>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="价格" align="center" min-width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.price }}积分</div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < fineSeatail.param.goodslist.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="handleTable(scope)">
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="board('good')" v-if="fineSeatail.param.goodslist.length<4"
        >
          <span>+</span>添加商品
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "fine",
  props: ["param"],
  data() {
    return {
      fineSeatail: this.param,
    };
  },
  methods: {
    titleInput(value) {
      this.fineSeatail.param.title = value;
      this.pubilcCom();
    },
    describeInput(value) {
      this.fineSeatail.param.text = value;
      this.pubilcCom();
    },
    board(type) {
      this.$emit("change", type);
    },
    pubilcCom() {
      let data = this.fineSeatail;
      this.$emit("change", "", data);
    },

    // 往上挪一位
    moreUp(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.fineSeatail.param.goodslist[index - 1])
      );
      this.$set(
        this.fineSeatail.param.goodslist,
        index - 1,
        this.fineSeatail.param.goodslist[index]
      );
      this.$set(this.fineSeatail.param.goodslist, index, options);

      this.pubilcCom();
    },
    // 往下挪一位
    moreDown(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.fineSeatail.param.goodslist[index + 1])
      );
      this.$set(
        this.fineSeatail.param.goodslist,
        index + 1,
        this.fineSeatail.param.goodslist[index]
      );
      this.$set(this.fineSeatail.param.goodslist, index, options);

      this.pubilcCom();
    },
    handleTable(scope) {
      let index = scope.$index;
      this.fineSeatail.param.goodslist.splice(index, 1);
      this.pubilcCom();
    },
  },
};
</script>
<style lang="scss" scoped>

.shoplister {
    width: 100%;
}

.shopimg {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 12px;
}

.shopimg img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.shoptext {
    width: 100%;
    float: left;
    width: calc(100% - 62px);
}

.shoptext h6 {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #333;
    font-weight: 500;
    line-height: 22px;
    max-height: 44px;
}
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    /deep/.el-input {
      width: 280px;
      height: 32px;
      line-height: 32px;
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .diy-radio-group {
      min-height: 32px;
    }
    .advertising {
      width: 242px;
      height: 116px;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      display: inline-block;
      overflow: hidden;
      line-height: 116px;
      .uploadImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .upload_img {
        width: 100%;
        height: 100%;
        span {
          display: block;
          font-size: 24px;
          color: #f0f0f0;
        }
      }
    }
    .advertising_toast {
      font-size: 12px;
      color: #666;
      margin-left: 10px;
      line-height: 116px;
    }
  }
  .diy_content {
    width: 100%;
  }
}
.addNav {
  width: 100%;
  height: 32px;
  line-height: 30px;
  border: 1px dashed #ccc;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
  cursor: pointer;
}
.handle {
  cursor: pointer;
  i {
    font-size: 20px;
  }
}
.dy_more {
  display: inline-block;
  width: 28px;
  height: 32px;
  cursor: pointer;
  vertical-align: sub;
  i {
    line-height: 32px;
  }
}
.title {
  height: 32px;
  line-height: 32px;
  /deep/.el-input {
    width: 100%;
    height: 32px;
    line-height: 32px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input__suffix{
      .el-input__suffix-inner{
        .el-input__icon{
          line-height: 32px;
        }
      }
    }
  }
  /deep/.el-select{
    width: 100%;
  }
}
</style>