<template>
  <div class="">
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">关键词</div>
      <div class="diy-group-content">
        <el-input
          v-model="mining.param.keyword"
          @change="keyChange"
          placeholder="请输入关键词"
        ></el-input>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">标题</div>
      <div class="diy-group-content">
        <el-input
          v-model="mining.param.title"
          @change="headlineInput"
          placeholder="请输入标题"
        ></el-input>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">文本</div>
      <div class="diy-group-content">
        <el-input
          v-model="mining.param.text"
          @change="textInput"
          placeholder="请输入文本"
        ></el-input>
      </div>
    </div>

    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">更多</div>
      <div class="diy-group-content">
        <div class="title">
          <el-input
            v-model="mining.param.more_link"
            readonly
            @click.native="linkChange($event, '', 'more_link')"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-link"
              style="color: #4877fb; font-size: 16px; line-height: 32px"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy_content">
        <el-table :data="mining.param.list" style="width: 100%">
          <el-table-column label="图片" min-width="100">
            <template slot-scope="scope">
              <div
                v-if="scope.row.pic_url"
                class="picurl"
                @click.stop="linkChange('', scope, 'img')"
              >
                <img :src="scope.row.pic_url" alt="" />
              </div>
              <div
                v-else
                class="uploadImg"
                @click.stop="linkChange('', scope, 'img')"
              >
                <span>+</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="标题" min-width="94">
            <template slot-scope="scope">
              <div class="title">
                <el-input
                  v-model="scope.row.title"
                  @change="titleInput($event, scope)"
                  placeholder="请输入标题名称"
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="描述" min-width="134">
            <template slot-scope="scope">
              <div class="title">
                <el-input
                  v-model="scope.row.describe"
                  @change="describeInput($event, scope)"
                  placeholder="请输入标题名称"
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="链接" min-width="157">
            <template slot-scope="scope">
              <div class="title">
                <el-input
                  v-model="scope.row.page_url"
                  readonly
                  @click.native="linkChange($event, scope, 'link')"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-link"
                    style="color: #4877fb; font-size: 16px; line-height: 32px"
                  ></i>
                </el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < mining.param.list.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="打开方式" min-width="102">
            <template slot-scope="scope">
              <div class="title">
                <el-select
                  v-model="scope.row.open_type"
                  @change="openChange($event, scope)"
                >
                  <el-option label="本窗口" value="0"></el-option>
                  <el-option label="新窗口" value="1"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="handleTable(scope)">
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="appendNav" 
        >
          <span>+</span>添加模块
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["param"],
  name: "political",
  data() {
    return {
      mining: this.param,
      openIndex: -1,
    };
  },
  methods: {
    keyChange(value) {
      this.mining.param.keyword = value;
      this.pubilcCom();
    },
    headlineInput(value) {
      this.mining.param.title = value;
      this.pubilcCom();
    },
    textInput(value) {
      this.mining.param.text = value;
      this.pubilcCom();
    },
    radioChange(value) {
      this.mining.param.style = value;
      this.pubilcCom();
    },
    titleInput(value, scope) {
      let index = scope.$index;
      this.mining.param.list[index].title = value;
      this.pubilcCom();
    },
    describeInput(value, scope) {
      let index = scope.$index;
      this.mining.param.list[index].describe = value;
      this.pubilcCom();
    },
    // 往上挪一位
    moreUp(scope) {
      let index = scope.$index;
        var options = JSON.parse(
          JSON.stringify(this.mining.param.list[index - 1])
        );
        this.$set(
          this.mining.param.list,
          index - 1,
          this.mining.param.list[index]
        );
        this.$set(this.mining.param.list, index, options);
     

      this.pubilcCom();
    },
    // 往下挪一位
    moreDown(scope) {
      let index = scope.$index;
        var options = JSON.parse(
          JSON.stringify(this.mining.param.list[index + 1])
        );
        this.$set(
          this.mining.param.list,
          index + 1,
          this.mining.param.list[index]
        );
        this.$set(this.mining.param.list, index, options);
     
      this.pubilcCom();
    },

    // 打开方式
    openChange(val, scope) {
      //console.log(val);
      let index = scope.$index;
      this.mining.param.list[index].open_type = val;
      this.pubilcCom();
    },
    appendNav() {
      let data = {
        pic_url: "",
        open_type: "0",
        page_url: "",
        describe: "",
        title: "",
      };
      this.mining.param.list.push(data);
    },
    linkChange(val, scope, type) {
      if (scope) {
        this.openIndex = scope.$index;
      }
      this.$emit("navchange", type);
    },
    handleTable(scope) {
      let index = scope.$index;
        this.mining.param.list.splice(index, 1);

      this.pubilcCom();
    },
    pubilcCom() {
      let data = this.mining;
      this.$emit("navchange", "", data);
    },
    // 添加品牌
    appendbrand() {
      this.$emit("navchange", "brand");
    },
    // 添加商品
    appendgood() {
      this.$emit("navchange", "good");
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    /deep/.el-input {
      width: 280px;
      height: 32px;
      line-height: 32px;
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .diy-radio-group {
      min-height: 32px;
    }
  }
  .diy_content {
    width: 100%;
  }
}
.picurl {
  width: 53px;
  height: 32px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.uploadImg {
  width: 50px;
  height: 100%;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  span {
    display: block;
    font-size: 24px;
    color: #f0f0f0;
  }
}
/deep/.el-table {
  background: #f0f0f0;
  .el-table--scrollable-x .el-table__body-wrapper {
    background: #fff !important;
  }
  .el-table__body-wrapper {
    background: #fff;
  }
  /deep/.el-table__body-wrapper.is-scrolling-left {
    background: #fff !important;
  }
}
.addNav {
  width: 100%;
  height: 32px;
  line-height: 30px;
  border: 1px dashed #ccc;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
  cursor: pointer;
}
.handle {
  cursor: pointer;
  i {
    font-size: 20px;
  }
}
.dy_more {
  display: inline-block;
  width: 28px;
  height: 32px;
  cursor: pointer;
  vertical-align: sub;
  i {
    line-height: 32px;
  }
}
.title {
  height: 32px;
  line-height: 32px;
  /deep/.el-input {
    width: 100%;
    height: 32px;
    line-height: 32px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input__suffix{
      .el-input__suffix-inner{
        .el-input__icon{
          line-height: 32px;
        }
      }
    }
  }
  /deep/.el-select{
    width: 100%;
  }
}
</style>