<template>
  <div class="container" ref="scrollDiv">
    <header_head></header_head>
    <div class="diy_pc_head d-flex justify-content-between align-items-center">
      <p class="head_title">{{ form_board.title }}</p>
      <div class="btn-group d-flex">
        <a
          href="javascript:void(0);"
          class="d-flex align-items-center justify-content-center"
          @click.stop="savefinish"
          >保存</a
        >
      </div>
    </div>

    <div class="panel-body" ref="imageTofile">
      <div class="d-flex flex-row">
        <div class="temp-add" @click.stop="addCom = !addCom">添加</div>
        <div class="componentModal" :class="[addCom ? 'active' : '']">
          <div class="element_wrap">
            <div class="element_close" @click="addCom = false">x</div>
            <div class="element_head">
              <label class="element-lable">模板名称</label>
              <el-input type="text" v-model="formName"></el-input>
            </div>
            <div class="element_toast">温馨提示：此页面最多可添加20个组件</div>
            <div class="element_nav">
              <div
                class="modules_name"
                :class="[baseicActive ? 'on' : '']"
                @click.stop="baseicActive = !baseicActive"
              >
                <span>基础组件</span>
                <i class="el-icon-arrow-down"></i>
              </div>
              <div class="modules_list" v-if="baseicActive">
                <div class="d-flex flex-row flex-wrap modulesLi">
                  <div
                    v-for="(items, index) in elemntList"
                    :key="index"
                    class="modulesSingle"
                    @click.stop="appendBase(items)"
                  >
                    <img :src="items.img" alt="" />
                    <div>{{ items.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overall">
          <div class="situation">
            <div class="situation_head">
              <div class="situation_header">
                <div class="top-left">
                  <img src="../../assets/diy/address.png" alt="" />
                  TTSHOP新零售电商系统，欢迎您
                </div>
                <ul class="top-right">
                  <li class="login">
                    <div class="ttshop_lodings">您好，请登录</div>
                    <div>免费注册</div>
                  </li>
                  <li class="top_last">
                    我的订单
                    <i class="xia el-icon-arrow-down"></i>
                  </li>
                  <li class="top_last">
                    个人中心
                    <i class="xia el-icon-arrow-down"></i>
                  </li>
                  <li class="top_last">
                    入驻商登录
                    <i class="xia el-icon-arrow-down"></i>
                  </li>
                  <li class="top_last">
                    移动端
                    <i class="xia el-icon-arrow-down"></i>
                  </li>
                  <li class="top_last">
                    商家支持
                    <i class="xia el-icon-arrow-down"></i>
                  </li>
                </ul>
              </div>
              <div class="index-search">
                <div class="search-box w">
                  <div class="logo">
                    <img src="../../assets/diy/logo.jpg" alt="" />
                  </div>
                  <div class="search-c">
                    <div class="search-item">
                      <div class="box">
                        <img
                          class="img-default img"
                          src="../../assets/diy/search.png"
                          alt
                        />
                        <input
                          placeholder="搜索商品"
                          class="search-input"
                          type="text"
                          v-model="searchtext"
                          ref="searchtext"
                        />
                      </div>
                      <div class="search-btn">搜索</div>
                    </div>
                    <ul class="search-default">
                      <li v-for="(item, index) in searchPast" :key="index">
                        <span v-text="item"></span>
                      </li>
                    </ul>
                  </div>
                  <div class="cat">
                    <img
                      class="img-default xia"
                      src="../../assets/diy/cat.svg"
                      alt
                    />
                    购物车
                    <span>1</span>
                  </div>
                </div>
              </div>
              <div
                class="index-nav"
                :style="{
                  backgroundColor: form_board.headnav.background.color,
                  color: form_board.headnav.font_color,
                }"
                
              >
                <div class="nav-box w">
                  <div class="nav-all">
                    <div class="img-all">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    全部商品分类
                    <i class="img-xia"></i>
                  </div>
                  <ul class="nav-list">
                    <template v-if="form_board.headnav.list.length">
                      <li
                        v-for="(item, index) in form_board.headnav.list"
                        :key="index"
                        :title="item.title"
                        :style="{ color: form_board.headnav.font_color }"
                      >
                        {{ item.title }}
                      </li>
                    </template>
                    <template v-else>
                      <li :style="{ color: form_board.headnav.font_color }">
                        请添加导航
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="module-hover-show" @click.stop="headerNav('header_nav')"></div>
              </div>
            </div>
            <!-- 组件 -->
            <div class="sortList">
              <template v-if="temp_list && temp_list.length">
                <div
                  class="module-box"
                  v-for="(item, index) in temp_list"
                  :key="item.id"
                >
                  <template v-if="item.type == 'banner'">
                    <banSwiper
                      :accept="item"  :pubicIndex="item.id"
                      @change="swiperChange"
                    ></banSwiper>
                  </template>
                  <template v-else>
                    <temPublic
                      :param="item" :pubicIndex="item.id"
                      @navchange="stencilNav"
                      @change="stencilChange"
                    ></temPublic>
                  </template>
                </div>
              </template>
              <template v-else>
                <div
                  class="d-flex justify-content-center align-items-center block-empty"
                >
                  <div class="empty_con">
                    <img src="../../assets/diy/empty.png" alt="" />
                    <div class="empty_title">
                      模板空空如也
                      <br />
                      快添加组件吧
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- 尾部 -->
            <div class="footer" v-if="false">
              <div class="foot_box">
                <div class="foot-t">
                  <div class="foot_wrap">
                    <ul class="foot_ul">
                      <li
                        v-for="(value, key) in form_board.footer.footer_advert
                          .list.length > 0
                          ? form_board.footer.footer_advert.list
                          : defaultList.footer.footer_advert_list"
                        :key="key"
                      >
                        <img :src="value.pic_url" alt />
                        <span v-text="value.title"></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="module-hover-show foot-edit"
                @click.stop="headerNav('footer')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog">
      <el-dialog
        :title="temp_type == 'header_nav' ? '主导航背景' : '编辑'"
        :visible.sync="chnnerVisible"
        width="62.5%"
      >
        <div class="template_container">
          <div class="template_wrap">
            <template v-if="temp_type == 'header_nav'">
              <headNav
                ref="headnav"
                @change="headChange"
                :headUrl="form_board.headnav"
                @navchange="navchange"
              ></headNav>
            </template>
            <template v-else-if="temp_type == 'footer'">
              <footFoot
                ref="footer_advert"
                :param="form_board.footer"
                @change="footerChange"
              ></footFoot>
            </template>
            <template v-else>
              <div v-for="(item, index) in temp_list">
                <template v-if="temp_index == index">
                  <template v-if="item.type == 'banner'">
                    <template v-if="banner_type == 'banner'">
                      <carousel
                        ref="carousels"
                        :param="item"
                        @navchange="carouselChange"
                      ></carousel>
                    </template>
                    <template v-else-if="banner_type == 'classify'">
                      <classify :param="item" @change="classChange"></classify>
                    </template>
                    <template v-else-if="banner_type == 'quick'">
                      <quick
                        ref="quick"
                        :param="item"
                        @navchange="quickNav"
                      ></quick>
                    </template>
                  </template>
                  <template v-else-if="item.type == 'channel'">
                    <political
                      ref="political"
                      :param="item"
                      @change="politicalChange"
                      @navchange="politicalNavChange"
                    ></political>
                  </template>
                  <template v-else-if="item.type == 'brand'">
                    <brandhandpick
                      ref="brandSelect"
                      @change="brandhandpick"
                      :param="item"
                    ></brandhandpick>
                  </template>
                  <template v-else-if="item.type == 'new'">
                    <newgood
                      ref="newgood"
                      :param="item"
                      @change="newgoodChange"
                    ></newgood>
                  </template>
                  <template v-else-if="item.type == 'recommend'">
                    <recommend
                      ref="recommend"
                      :param="item"
                      @change="recommendChange"
                    ></recommend>
                  </template>
                  <template v-else-if="item.type == 'character'">
                    <character
                      ref="character"
                      :param="item"
                      @change="characterChange"
                    ></character>
                  </template>
                  <template v-else-if="item.type == 'fine'">
                    <fine ref="fine" :param="item" @change="fineChange"></fine>
                  </template>
                  <template v-else-if="item.type == 'hot'">
                    <hot ref="hot" :param="item" @change="hotChange"></hot>
                  </template>
                  <template v-else-if="item.type == 'like'">
                    <like ref="like" :param="item" @change="likeChange"></like>
                  </template>
                  <template v-else-if="item.type == 'floor'">
                    <floor
                      ref="floor"
                      :param="item"
                      @change="floorChange"
                      @floor="linkChange"
                    ></floor>
                  </template>
                </template>
              </div>
            </template>
          </div>
        </div>
      </el-dialog>
    </div>
    <linkUrlChoice ref="selectLink" @change="linkGet" :mobileActive="mobileActive"></linkUrlChoice>
    <pictureSelect
      ref="pictureSelect"
      :multipled="is_multiple"
      @change="imgbrand($event)"
    ></pictureSelect>
    <brandChoice ref="brandChoice" @change="brandChange" :mobileActive="mobileActive"></brandChoice>
    <shopselector ref="shopList" @change="goodshoplist($event)" :mobileActive="mobileActive" :isPc="true" :fitmentClass="fitmentClass"></shopselector>
    <shopClass ref="shopClass" @change="classification" :mobileActive="mobileActive" :isPc="true"></shopClass>
  </div>
</template>
<script>
import headNav from "./components/headerNav.vue";
import banSwiper from "./components/banner.vue";
import carousel from "./components/carousel.vue";
import classify from "./components/classify.vue";
import quick from "./components/quick.vue";
import temPublic from "./components/tem.vue";
import political from "./components/political.vue";
import character from "./components/character.vue";
import floor from "./components/floor.vue";
import recommend from "./components/recommend.vue";
import footFoot from "./components/footer.vue";
import fine from "./components/fine.vue";
import hot from "./components/hot.vue";
import like from "./components/like.vue";
import brandhandpick from "./components/brandhandpick.vue";
import newgood from "./components/new.vue";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      form_board: {
        title: "pc模板编辑",
        headnav: {
          list: [],
          font_color: "#333333",
          background: {
            back_style: 0,
            color: "#FFFFFF",
          },
        }, //导航
        footer: {
          footer_advert: {
            list: [],
            is_show: 1,
          },
        },
      },
      defaultList: {
        footer: {
          footer_advert_list: [
            {
              pic_url: require("../../assets/diy/default.png"),
              title: "正品保障，质量过关",
            },
            {
              pic_url: require("../../assets/diy/default.png"),
              title: "品类众多，一站解决",
            },
            {
              pic_url: require("../../assets/diy/default.png"),
              title: "7天无理由退货",
            },
            {
              pic_url: require("../../assets/diy/default.png"),
              title: "15天免费换货",
            },
          ],
        },
      },
      addCom: false,
      formName: "", //  模板名称
      baseicActive: true, //展示基础组件
      elemntList: [
        {
          type: "banner",
          img: require("../../assets/diy/banner.svg"),
          name: "轮播图",
          param: {
            classify_opacity: 0.7,
            classify_font_color: "rgb(51, 51, 51)",
            classify_background_color:'#FFFFFF',
            style: 1,
            fill: 0,
            list: [],
            icon_list: [],
            classify_style:1,
          },
        },
        {
          type: "channel",
          img: require("../../assets/diy/channel.svg"),
          name: "频道",
          param: {
            more_link: "",
            keyword: "政采", //关键字
            title: "频道", //标题
            text: "", //文本
            list: [],
          },
        },
        {
          type: "new",
          img: require("../../assets/diy/new.svg"),
          name: "新品首发",
          param: {
            more_link: "",
            title: "新品首发",
            text: "",
            goodslist: [],
          },
        },
        {
          type: "brand",
          img: require("../../assets/diy/brand.svg"),
          name: "精选品牌",
          param: {
            more_link: "",
            keyword: "精选", //关键字
            title: "品牌", //标题
            text: "", //文本
            list: [],
          },
        },
        {
          type: "recommend",
          img: require("../../assets/diy/fine.svg"),
          name: "好物推荐",
          param: {
            keyword: "好物",
            title: "推荐",
            text: "",
            style: 0,
            discount: 0, //折扣
            advertising: [],
            goodslist: [],
          },
        },
        {
          type: "character",
          img: require("../../assets/diy/gexing.svg"),
          name: "个性推荐",
          param: {
            goodslist: [],
            title: "个性商品",
            text: "",
          },
        },
        {
          type: "fine",
          img: require("../../assets/diy/good_two.svg"),
          name: "推荐",
          param: {
            title: "好物推荐",
            text: "",
            page_url: "",
            pic_url: "",
            goodslist: [],
          },
        },
        {
          type: "hot",
          img: require("../../assets/diy/hot.svg"),
          name: "热销商品",
          param: {
            title: "商品热销榜",
            cat_list: [],
          },
        },
        {
          type: "like",
          img: require("../../assets/diy/like.svg"),
          name: "猜您喜欢",
          param: {
            title: "为您推荐",
            cat_list: [],
          },
        },
        {
          type: "floor",
          img: require("../../assets/diy/floor.svg"),
          name: "楼层",
          param: {
            title: "楼层",
            text: "m",
            hot_url: "",
            hot_list: [],
            class_url: "",
            cat_list: [],
          },
        },
      ], //组件列表
      searchtext: "",
      searchPast: ["女装", "笔记本", "男鞋"],
      temp_type: "",
      chnnerVisible: false,
      headUrl: "",
      temp_list: [], //页面组件列表
      temp_index: -1, //选中的那个组件
      banner_type: "", //选择的bannner组件中的那个模快
      is_multiple: 0,
      politicalType: "", //选择的是不是更多
      boardId: this.$route.query.id,
      stencilData: {}, //编辑模板数据
      cover_img_url: "", //截图
      mobileActive:this.$route.query.active_id||this.$route.query.activity_id||'0',
      fitmentClass:[],
    };
  },
  components: {
    headNav,
    banSwiper,
    carousel,
    classify,
    quick,
    temPublic,
    political,
    character,
    floor,
    recommend,
    footFoot,
    fine,
    hot,
    like,
    brandhandpick,
    newgood,
  },
  created() {
    if (this.boardId) {
      this.getBoard();
    }
  },
  methods: {
    getElemnt() {
      let that = this;
      this.$get(this.$apis.getElemnt)
        .then((res) => {
          if (res.code == 200) {
          } else {
            that.$message({
              type: "warning",
              message: res.message,
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          }
        })
        .catch((err) => {
          that.common.message(that, err.message);
        });
    },
    getBoard() {
      let that = this;
      this.$get(this.$apis.savefinish + "/" + this.boardId)
        .then((res) => {
          if (res.code == 200) {
            that.temp_list = res.data.template;
            that.getExtend();
            // this.banner = head;
            // this.sortIndex=this.accept.index;
            that.form_board.headnav = res.data.header;
            that.formName = res.data.name;
            that.stencilData = res.data;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getExtend() {
      let that = this;
      this.$get(
        this.$apis.templatExtend + this.boardId + "/get_template_extend"
      )
        .then((res) => {
          let temp_list = this.temp_list;
          for (let i = 0; i < temp_list.length; i++) {
            for (var w in res.data.goods_list) {
              if (
                temp_list[i].type == "new" ||
                temp_list[i].type == "recommend" ||
                temp_list[i].type == "character" ||
                temp_list[i].type == "fine"
              ) {
                for (let j = 0; j < temp_list[i].param.goodslist.length; j++) {
                  if (temp_list[i].param.goodslist[j] == w) {
                    // temp_list[i].param.goodslist[j] = res.data.goods_list[w];
                    this.$set(
                      temp_list[i].param.goodslist,
                      j,
                      res.data.goods_list[w]
                    );
                  }
                }
                if (temp_list[i].type == "recommend") {
                  for (
                    let e = 0;
                    e < temp_list[i].param.advertising.length;
                    e++
                  ) {
                    if (temp_list[i].param.advertising[e] == w) {
                      // temp_list[i].param.goodslist[j] = res.data.goods_list[w];
                      this.$set(
                        temp_list[i].param.advertising,
                        e,
                        res.data.goods_list[w]
                      );
                    }
                  }
                }
              }
              if (
                temp_list[i].type == "like" ||
                temp_list[i].type == "hot" ||
                temp_list[i].type == "floor"
              ) {
                for (let a = 0; a < temp_list[i].param.cat_list.length; a++) {
                  for (
                    let b = 0;
                    b < temp_list[i].param.cat_list[a].goodslist.length;
                    b++
                  ) {
                    if (temp_list[i].param.cat_list[a].goodslist[b] == w) {
                      // temp_list[i].param.goodslist[j] = res.data.goods_list[w];
                      this.$set(
                        temp_list[i].param.cat_list[a].goodslist,
                        b,
                        res.data.goods_list[w]
                      );
                    }
                  }
                }
                if (temp_list[i].type == "floor") {
                  for (let c = 0; c < temp_list[i].param.hot_list.length; c++) {
                    if (temp_list[i].param.hot_list[c] == w) {
                      this.$set(
                        temp_list[i].param.hot_list,
                        c,
                        res.data.goods_list[w]
                      );
                    }
                  }
                }
              }
            }
          }
          // //console.log(temp_list)
          that.$nextTick(() => {
            that.$set(that, "temp_list", temp_list);
            that.$forceUpdate();
            // //console.log(that.temp_list);
          });
        })
        .catch((err) => {
          //console.log(err);
          that.common.message(that, err.message);
        });
    },
    headerNav(val = "") {
      this.temp_type = val;
      this.chnnerVisible = !this.chnnerVisible;
    },
    // 导航的响应值
    headChange(val) {
      let head = Object.assign(this.form_board.headnav, val);
      this.form_board.headnav = head;
      this.$forceUpdate();
    },
    // 导航中的选择地址
    navchange() {
      this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
    },
    // 底部的响应值
    footerChange(value, type = "") {
      if (type) {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      } else {
        let head = Object.assign(this.form_board.footer, value);
        this.form_board.footer = head;
      }

      this.$forceUpdate();
    },
    // 选择链接的响应值
    linkGet(val = "") {
      if (!val) {
        return false;
      }
      let tem_list = this.temp_list;
      if (this.temp_type == "header_nav") {
        // this.headUrl=val;
        if (val) {
          this.form_board.headnav.list[this.$refs.headnav.openIndex].page_url =
            val.link;
        }
      } else {
        if (tem_list[this.temp_index].type == "banner") {
          if (this.banner_type == "banner") {
            if (val) {
              this.temp_list[this.temp_index].param.list[
                this.$refs.carousels[0].openIndex
              ].page_url = val.link;
            }
          } else if (this.banner_type == "quick") {
            this.temp_list[this.temp_index].param.icon_list[
              this.$refs.quick[0].openIndex
            ].page_url = val.link;
          }
        } else if (tem_list[this.temp_index].type == "channel") {
          if (this.politicalType) {
            this.temp_list[this.temp_index].param.more_link = val.link;
          } else {
            this.temp_list[this.temp_index].param.list[
              this.$refs.political[0].openIndex
            ].page_url = val.link;
          }
        } else if (tem_list[this.temp_index].type == "brand") {
          this.temp_list[this.temp_index].param.more_link = val.link;
        } else if (tem_list[this.temp_index].type == "fine") {
          this.temp_list[this.temp_index].param.page_url = val.link;
        } else if (tem_list[this.temp_index].type == "floor") {
          if (this.$refs.floor[0].tem_type == "hot_link") {
            this.temp_list[this.temp_index].param.hot_url = val.link;
          }
        } else if (tem_list[this.temp_index].type == "new") {
          this.temp_list[this.temp_index].param.more_link = val.link;
        }
      }
    },
    // 选择图片的响应值
    imgbrand(value) {
      if (!value) {
        return false;
      }
      if (this.temp_type == "footer") {
        // //console.log(this.$refs.footer_advert);
        this.form_board.footer.footer_advert.list[
          this.$refs.footer_advert.optionIndex
        ].pic_url = value[0].file_url;
        return false;
      }
      let tem_list = this.temp_list,
        data = this.temp_list[this.temp_index];
      if (data.type == "banner") {
        if (this.banner_type == "banner") {
          this.temp_list[this.temp_index].param.list[
            this.$refs.carousels[0].openIndex
          ].pic_url = value[0].file_url;
        } else if (this.banner_type == "quick") {
          this.temp_list[this.temp_index].param.icon_list[
            this.$refs.quick[0].openIndex
          ].pic_url = value[0].file_url;
        }
      } else if (data.type == "channel") {
        if (this.politicalType) {
          this.temp_list[this.temp_index].param.more_link = value[0].file_url;
        } else {
          this.temp_list[this.temp_index].param.list[
            this.$refs.political[0].openIndex
          ].pic_url = value[0].file_url;
        }
      } else if (data.type == "recommend") {
        this.temp_list[this.temp_index].param.pic_url = value[0].file_url;
      } else if (data.type == "fine") {
        this.temp_list[this.temp_index].param.pic_url = value[0].file_url;
      }
    },
    // 选择品牌的响应值
    brandChange(value) {
      let tem_list = this.temp_list;
      if (tem_list[this.temp_index].type == "brand") {
        let arr = this.temp_list[this.temp_index].param.list;
        arr = arr.concat(value);
        var newArr = arr.filter(function (item, index) {
          return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
        });
        this.temp_list[this.temp_index].param.list = newArr;
      }
    },
    // 选择商品的响应值
    goodshoplist(list) {
      let tem_list = this.temp_list,
        temp_index = this.temp_index;
      if (tem_list[this.temp_index].type == "new") {
        let arr = this.temp_list[this.temp_index].param.goodslist;
        arr = arr.concat(list);
        var newArr = arr.filter(function (item, index) {
          return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
        });
        this.temp_list[this.temp_index].param.goodslist = newArr;
      } else if (tem_list[this.temp_index].type == "character") {
        let arr = this.temp_list[this.temp_index].param.goodslist;
        arr = arr.concat(list);
        var newArr = arr.filter(function (item, index) {
          return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
        });
        let obj = newArr.slice(0, 5);
        this.temp_list[this.temp_index].param.goodslist = obj;
      } else if (tem_list[this.temp_index].type == "floor") {
        if (this.$refs.floor[0].tem_type == "hot_good") {
          let arr = this.temp_list[this.temp_index].param.hot_list;
          arr = arr.concat(list);
          var newArr = arr.filter(function (item, index) {
            return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
          });
          let obj = newArr.slice(0, 5);
          this.temp_list[this.temp_index].param.hot_list = obj;
        } else {
          let hotIndex = this.$refs.floor[0].goodIndex;
          let arr =
            this.temp_list[this.temp_index].param.cat_list[hotIndex].goodslist;
          arr = arr.concat(list);
          var newArr = arr.filter(function (item, index) {
            return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
          });
          let obj = newArr.slice(0, 8);
          this.temp_list[temp_index].param.cat_list[hotIndex].goodslist = obj;
          let newobj = JSON.parse(
            JSON.stringify(this.temp_list[this.temp_index].param.cat_list)
          );
          newobj[hotIndex].goodslist = obj;
          this.$set(this.temp_list[this.temp_index].param, "cat_list", newobj);
          this.$forceUpdate();
        }
      } else if (tem_list[this.temp_index].type == "recommend") {
        if (this.$refs.recommend[0].tem_type == "advertising") {
          let arr = this.temp_list[this.temp_index].param.advertising;
          arr = arr.concat(list);
          var newArr = arr.filter(function (item, index) {
            return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
          });
          this.temp_list[this.temp_index].param.advertising = newArr;
        } else {
          let arr = this.temp_list[this.temp_index].param.goodslist;
          arr = arr.concat(list);
          var newArr = arr.filter(function (item, index) {
            return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
          });
          let obj = newArr.slice(0, 4);
          this.temp_list[this.temp_index].param.goodslist = obj;
        }
      } else if (tem_list[this.temp_index].type == "fine") {
        let arr = this.temp_list[this.temp_index].param.goodslist;
        arr = arr.concat(list);
        var newArr = arr.filter(function (item, index) {
          return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
        });
        let obj = newArr.slice(0, 4);
        this.temp_list[this.temp_index].param.goodslist = obj;
      } else if (tem_list[this.temp_index].type == "hot") {
        let hotIndex = this.$refs.hot[0].goodIndex;
        let arr =
          this.temp_list[this.temp_index].param.cat_list[hotIndex].goodslist;
        arr = arr.concat(list);
        var newArr = arr.filter(function (item, index) {
          return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
        });
        this.temp_list[temp_index].param.cat_list[hotIndex].goodslist = newArr;
        let newobj = JSON.parse(
          JSON.stringify(this.temp_list[this.temp_index].param.cat_list)
        );
        newobj[hotIndex].goodslist = newArr;
        this.$set(this.temp_list[this.temp_index].param, "cat_list", newobj);
        this.$forceUpdate();
        //console.log(this.temp_list);
      } else if (tem_list[this.temp_index].type == "like") {
        let hotIndex = this.$refs.like[0].goodIndex;
        let arr =
          this.temp_list[this.temp_index].param.cat_list[hotIndex].goodslist;
        arr = arr.concat(list);
        var newArr = arr.filter(function (item, index) {
          return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
        });
        this.temp_list[temp_index].param.cat_list[hotIndex].goodslist = newArr;
        let newobj = JSON.parse(
          JSON.stringify(this.temp_list[this.temp_index].param.cat_list)
        );
        newobj[hotIndex].goodslist = newArr;
        this.$set(this.temp_list[this.temp_index].param, "cat_list", newobj);
      }
      this.$forceUpdate();
    },
    // 选择分类的响应值
    classification(value) {
      let temp_list = this.temp_list;
      if (temp_list[this.temp_index].type == "hot") {
        let arr = this.temp_list[this.temp_index].param.cat_list;
        arr = arr.concat(value);
        for (let i = 0; i < arr.length; i++) {
          for (let j = i + 1; j < arr.length; j++) {
            if (arr[i].id === arr[j].id) {
              arr.splice(j, 1);
              j--;
            }
          }
        }
        // var newArr = arr.filter(function (item, index) {
        //   return arr.indexOf(item) === index; // 因为indexOf 只能查找到第一个
        // });
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].hasOwnProperty("goodslist")) {
            arr[i].goodslist = [];
          }
        }
        this.temp_list[this.temp_index].param.cat_list = arr;
      } else if (temp_list[this.temp_index].type == "like") {
        let arr = this.temp_list[this.temp_index].param.cat_list;
        arr = arr.concat(value);
        for (let i = 0; i < arr.length; i++) {
          for (let j = i + 1; j < arr.length; j++) {
            if (arr[i].id === arr[j].id) {
              arr.splice(j, 1);
              j--;
            }
          }
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].hasOwnProperty("goodslist")) {
            arr[i].goodslist = [];
          }
        }
        this.temp_list[this.temp_index].param.cat_list = arr;
      } else if (temp_list[this.temp_index].type == "floor") {
        let arr = this.temp_list[this.temp_index].param.cat_list;
        arr = arr.concat(value);
        for (let i = 0; i < arr.length; i++) {
          for (let j = i + 1; j < arr.length; j++) {
            if (arr[i].id === arr[j].id) {
              arr.splice(j, 1);
              j--;
            }
          }
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].hasOwnProperty("goodslist")) {
            arr[i].goodslist = [];
          }
        }
        let obj = arr.slice(0, 5);
        this.temp_list[this.temp_index].param.cat_list = obj;
      }
    },
    // 增加组件
    appendBase(item) {
      let that = this;
      if (this.temp_list.length > 20 || this.temp_list.length == 20) {
        this.$message({
          type: "warning",
          message: "最多添加20个组件",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      let items = JSON.parse(JSON.stringify(item));
      let data = {
        type: item.type,
        index: this.temp_list.length,
        id: this.$nanoid(),
      };
      let objdata = Object.assign(data, items);
      this.temp_list.push(objdata);
      this.$nextTick(() => {
        let scrollElem = that.$refs.scrollDiv;
        window.scrollTo({ top: scrollElem.scrollHeight, behavior: "smooth" });
      });
    },
    // 轮播图的响应
    swiperChange(value) {
      this.temp_type ='';
      let index = this.temp_list.findIndex((item) => item.id == value.id);
      this.temp_index = index;
      this.banner_type = value.type;
      if (value.type == "banner") {
        this.chnnerVisible = !this.chnnerVisible;
      } else if (value.type == "del") {
        this.temp_list.splice(index, 1);
      } else if (value.type == "copy") {
        let obj = JSON.parse(JSON.stringify(this.temp_list[this.temp_index]));
        obj.index = this.temp_list.length;
        obj.id = this.$nanoid();
        this.temp_list.push(obj);
      } else if (value.type == "down") {
        this.$set(this.temp_list[index], "index", index + 1);
        this.$set(this.temp_list[index + 1], "index", index);
        this.temp_list.sort((a, b) => {
          return a.index - b.index;
        });
        // var options = JSON.parse(JSON.stringify(this.temp_list[index + 1]));
        // this.$set(this.temp_list, index + 1, this.temp_list[index]);
        // this.$set(this.temp_list, index, options);
      } else if (value.type == "up") {
        this.$set(this.temp_list[index], "index", index - 1);
        this.$set(this.temp_list[index - 1], "index", index + 1);
        this.temp_list.sort((a, b) => {
          return a.index - b.index;
        });
        // var options = JSON.parse(JSON.stringify(this.temp_list[index - 1]));
        // this.$set(this.temp_list, index - 1, this.temp_list[index]);
        // this.$set(this.temp_list, index, options);
      } else if (value.type == "classify") {
        this.chnnerVisible = !this.chnnerVisible;
      } else if (value.type == "quick") {
        this.chnnerVisible = !this.chnnerVisible;
      }
      this.$forceUpdate();
    },
    // 轮播图的选择链接
    carouselChange(type, value) {
      if (type == "link") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else if (type == "img") {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      } else {
        this.temp_list[this.temp_index].param.list = value;
      }
    },
    // 分类的选择
    classChange(value) {
      let obj = JSON.parse(JSON.stringify(value));
      obj.param.classify_opacity = obj.param.classify_opacity / 100;
      this.$set(this.temp_list, this.temp_index, obj);
      // //console.log(this.temp_list)
    },
    // 用户的选择
    quickNav(type, value) {
      if (type == "link") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else if (type == "img") {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      } else {
        // //console.log(value);
        this.temp_list[this.temp_index].param.icon_list = value;
      }
    },
    // 其它组件
    stencilChange(value) {
      console.log(value)
      this.temp_type = "";
      this.banner_type="";
      this.temp_index=this.temp_list.findIndex(item=>item.id==value.index)
      // this.temp_index = value.index;
      console.log(this.temp_index)
      this.chnnerVisible = !this.chnnerVisible;
    },
    stencilNav(value) {
      let index = this.temp_list.findIndex((item) => item.id == value.id);
      this.temp_index = index;
      if (value.type == "del") {
        this.temp_list.splice(index, 1);
      } else if (value.type == "copy") {
        let obj = JSON.parse(JSON.stringify(this.temp_list[this.temp_index]));
        obj.index = this.temp_list.length;
        obj.id = this.$nanoid();
        this.temp_list.push(obj);
      } else if (value.type == "down") {
        this.$set(this.temp_list[index], "index", index + 1);
        this.$set(this.temp_list[index + 1], "index", index);
        this.temp_list.sort((a, b) => {
          return a.index - b.index;
        });
        this.$forceUpdate();
      } else if (value.type == "up") {
        this.$set(this.temp_list[index], "index", index - 1);
        this.$set(this.temp_list[index - 1], "index", index + 1);
        this.temp_list.sort((a, b) => {
          return a.index - b.index;
        });
      }
      // //console.log(this.temp_list);
    },
    // 政采模块
    politicalChange(value) {
      this.temp_index = value.index;
      //console.log(value);
      if (value.type == "del") {
        this.temp_list.splice(this.temp_index, 1);
      } else if (value.type == "copy") {
        let obj = JSON.parse(JSON.stringify(this.temp_list[this.temp_index]));
        obj.index = this.temp_list.length;
        this.temp_list.push(obj);
      } else if (value.type == "down") {
        let index = value.index;
        var options = JSON.parse(JSON.stringify(this.temp_list[index + 1]));
        this.$set(this.temp_list, index + 1, this.temp_list[index]);
        this.$set(this.temp_list, index, options);
      } else if (value.type == "up") {
        let index = value.index;
        var options = JSON.parse(JSON.stringify(this.temp_list[index - 1]));
        this.$set(this.temp_list, index - 1, this.temp_list[index]);
        this.$set(this.temp_list, index, options);
      }
    },
    politicalNavChange(type, value) {
      this.politicalType = "";
      if (type == "link") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else if (type == "img") {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      } else if (type == "more_link") {
        this.politicalType = type;
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else {
        this.temp_list[this.temp_index] = value;
      }
    },
    brandhandpick(type, data) {
      if (type == "brand") {
        this.$refs.brandChoice.brandVisible =
          !this.$refs.brandChoice.brandVisible;
        this.$refs.brandChoice.multipleSelection =
          this.temp_list[this.temp_index].param.brand;
      } else if (type == "more_link") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else {
        this.temp_list[this.temp_index] = data;
      }
    },
    newgoodChange(type, data) {
      this.fitmentClass=[];
      if (type == "more_link") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else if (type == "good") {
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
      } else {
        this.temp_list[this.temp_index] = data;
      }
    },
    // 个性商品组件
    characterChange(type, value) {
      this.fitmentClass=[];
      if (type) {
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
      } else {
        this.temp_list[this.temp_index] = value;
      }
    },
    // 楼层
    floorChange(type, value) {
      this.fitmentClass=[];
      if (type) {
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
      } else {
        this.temp_list[this.temp_index] = value;
      }
    },
    linkChange(type, data) {
      this.fitmentClass=[];
      if (type == "hot_link") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else if (type == "hot_good") {
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
      } else if (type == "class") {
        this.$refs.shopClass.classVisible = !this.$refs.shopClass.classVisible;
      } else if (type == "good") {
        this.fitmentClass = data.class_id;
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
      }
    },
    // 保存
    savefinish() {
      if (!this.formName) {
        this.$message({
          type: "warning",
          message: "模板名称不能为空",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      this.toImage();
    },
    conserve() {
      let that = this;
      let arr = [];
      let temp_list = JSON.parse(JSON.stringify(this.temp_list));

      for (let i = 0; i < temp_list.length; i++) {
        arr.push(temp_list[i]);
        if (
          temp_list[i].type == "recommend" ||
          temp_list[i].type == "new" ||
          temp_list[i].type == "fine" ||
          temp_list[i].type == "character"
        ) {
          if (temp_list[i].param.goodslist.length) {
            let goodlist = [];
            for (let j = 0; j < temp_list[i].param.goodslist.length; j++) {
              if (temp_list[i].param.goodslist[j]) {
                goodlist.push(temp_list[i].param.goodslist[j].id);
              }
            }
            arr[i].param.goodslist = goodlist;
          }
          if (temp_list[i].type == "recommend") {
            let advertising = [];
            for (let j = 0; j < temp_list[i].param.advertising.length; j++) {
              if (temp_list[i].param.advertising[j]) {
                advertising.push(temp_list[i].param.advertising[j].id);
              }
            }
            arr[i].param.advertising = advertising;
          }
        }
        if (
          temp_list[i].type == "like" ||
          temp_list[i].type == "hot" ||
          temp_list[i].type == "floor"
        ) {
          for (let a = 0; a < temp_list[i].param.cat_list.length; a++) {
            let complex = [];
            for (
              let b = 0;
              b < temp_list[i].param.cat_list[a].goodslist.length;
              b++
            ) {
              if (temp_list[i].param.cat_list[a].goodslist[b]) {
                complex.push(temp_list[i].param.cat_list[a].goodslist[b].id);
              }
            }
            arr[i].param.cat_list[a].goodslist = complex;
          }
          if (temp_list[i].type == "floor") {
            let hot_list = [];
            for (let c = 0; c < temp_list[i].param.hot_list.length; c++) {
              if (temp_list[i].param.hot_list[c]) {
                hot_list.push(temp_list[i].param.hot_list[c].id);
              }
            }
            arr[i].param.hot_list = hot_list;
          }
        }
      }
      // //console.log(arr);
      let data = {
        template: arr,
        header: this.form_board.headnav,
        footer: [],
        name: this.formName,
        cover_img_url: this.cover_img_url,
        activity_id:this.mobileActive,
      };
      if (this.boardId) {
        this.redactBoard(data);
        return false;
      }
      this.$post(this.$apis.savefinish, data)
        .then((res) => {
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "保存成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
            setTimeout(function () {
              // that.$router.replace({
              //   path:'/client/pc/diy',
              //   query:{
              //     active_id:that.mobileActive,
              //   }
              // });
              // that.$router.go(-1);
            }, 1500);
          } else {
            that.common.message(this, res.message);
          }
        })
        .catch((err) => {
          that.common.message(that, err.message);
        });
    },
    redactBoard(value) {
      let that = this;
      let data = value;
      data.type = this.stencilData.type;
      data.deleted_at = this.stencilData.deleted_at;
      data.status = this.stencilData.status;
      data.is_index = this.stencilData.is_index;
      data.page_id = this.stencilData.page_id;
      data.id = this.boardId;
      this.$put(this.$apis.savefinish + "/" + this.boardId, data)
        .then((res) => {
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "编辑成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
            setTimeout(function () {
              // that.$router.replace({
              //   path:'/client/pc/diy',
              //   query:{
              //     active_id:that.mobileActive,
              //   }
              // });
              // that.$router.go(-1);
            }, 1500);
          } else {
            that.common.message(that, res.message);
          }
        })
        .catch((err) => {
          //console.log(err);
          that.common.message(that, err.message);
        });
    },
    // 页面元素转图片
    toImage() {
      // 手动创建一个 canvas 标签
      const canvas = document.createElement("canvas");
      // 获取父标签，意思是这个标签内的 DOM 元素生成图片
      // imageTofile是给截图范围内的父级元素自定义的ref名称
      let canvasBox = this.$refs.imageTofile;
      // 获取父级的宽高
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";
      const context = canvas.getContext("2d");
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true,
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        let dataURL = canvas.toDataURL("image/png");
        let fileName = this.Generatename();
        let fileImg = this.dataURLtoFile(dataURL, fileName);
        // //console.log(fileImg);
        this.uploadImg(fileImg);
      });
    },
    uploadImg(file) {
      // //console.log(file)
      let that = this;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("is_ignore", true);
      let config = {
        headers: {
          "Content-Type": "mutipart/form-data",
        },
      };
      this.$post(this.$apis.upImg, formData, config)
        .then((res) => {
          // //console.log(res);
          if (res.code == 200) {
            that.cover_img_url = res.data.url;
            that.conserve();
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          //console.log(err);
          this.common.message(this, err);
        });
    },
    Generatename() {
      const yy = new Date().getFullYear();
      const MM =
        new Date().getMonth() + 1 < 10
          ? "0" + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1;
      const dd =
        new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate();
      const HH =
        new Date().getHours() < 10
          ? "0" + new Date().getHours()
          : new Date().getHours();
      const mm =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      const ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      return yy + MM + dd + "-" + HH + mm + ss+'.png';
    },
    dataURLtoFile(dataurl, fileName) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    },
    recommendChange(type = "", data) {
      this.fitmentClass=[];
      if (type) {
        if (type != "img") {
          this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
        } else {
          this.$refs.pictureSelect.photoVisible =
            !this.$refs.pictureSelect.photoVisible;
        }
      } else {
        this.temp_list[this.temp_index] = data;
      }
    },
    fineChange(type, data) {
      this.fitmentClass=[];
      if (type == "img") {
        this.$refs.pictureSelect.photoVisible =
          !this.$refs.pictureSelect.photoVisible;
      } else if (type == "link") {
        this.$refs.selectLink.linkVisible = !this.$refs.selectLink.linkVisible;
      } else if (type == "good") {
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
      } else {
        this.temp_list[this.temp_index] = data;
      }
    },
    hotChange(type, data) {
      this.fitmentClass=[];
      if (type == "class") {
        this.$refs.shopClass.classVisible = !this.$refs.shopClass.classVisible;
      } else if (type == "good") {
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
        this.fitmentClass = data.class_id;
      } else {
        this.temp_list[this.temp_index] = data;
      }
    },
    likeChange(type, data) {
      this.fitmentClass=[];
      if (type == "class") {
        this.$refs.shopClass.classVisible = !this.$refs.shopClass.classVisible;
      } else if (type == "good") {
        this.$refs.shopList.shoptype = !this.$refs.shopList.shoptype;
        this.fitmentClass = data.class_id;
      } else {
        this.temp_list[this.temp_index] = data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.w {
  width: 1190px;
  margin: 0 auto;
}
.container {
  min-width: 100%;
  padding: 0px;
  background: #fff;
  border-radius: 4px;
  .diy_pc_head {
    position: fixed;
    top: 46px;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    .head_title {
      margin-bottom: 0;
      font-size: 14px;
      color: #666;
    }
    .btn-group {
      position: relative;
      vertical-align: middle;
      margin-right: 40px;
      a {
        width: 82px;
        height: 28px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #c9d6fb;
        margin-right: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #4877fb;
        transition: opacity 0.3s;
      }
      a:last-child {
        margin-right: 0;
        background-color: #4877fb;
        border-color: #4877fb;
        color: #fff;
      }
    }
  }
  .panel-body {
    padding: 0px;
    .temp-add {
      position: fixed;
      top: 40%;
      left: 20px;
      z-index: 5;
      background: #fff;
      width: 65px;
      height: 65px;
      line-height: 65px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(64, 66, 84, 0.23);
    }
    .componentModal {
      position: fixed;
      top: 26%;
      left: -25rem;
      z-index: 13;
      width: 308px;
      height: 400px;
      padding: 0 !important;
      background: #fff;
      border-radius: 4px;
      transition: left 0.3s;
      box-shadow: 0 3px 6px rgba(64, 66, 84, 0.23);
      .element_wrap {
        // width: 100%;
        padding: 16px;
        overflow-y: auto;
        max-height: 100%;
        height: 88%;
        .element_close {
          position: absolute;
          right: -10px;
          top: -10px;
          z-index: 99999;
          font-size: 14px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          cursor: pointer;
          background: #5079f3;
          border-radius: 50%;
          color: #fff;
        }
        .element_head {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          .element-lable {
            padding-top: calc(0.35rem - 1px);
            padding-bottom: calc(0.35rem - 1px);
            position: relative;
            &::before {
              content: "*";
              display: inline;
              position: absolute;
              left: -10px;
              color: #ff4544;
              font-weight: bolder;
              top: 38%;
              right: -10px;
            }
          }
          > .el-input {
            width: 174px;
            height: 30px;
            line-height: 30px;
            margin-left: 8px;
            input {
              height: 30px;
              line-height: 30px;
            }
            /deep/.el-input__inner {
              height: 30px;
              line-height: 30px;
            }
          }
        }
        .element_toast {
          color: #d9534f;
          font-size: 14px;
          margin-bottom: 14px;
        }
        .element_nav {
          width: 100%;
          padding-bottom: 7px;
          .modules_name {
            cursor: pointer;
            margin-top: 14px;
            height: 35px;
            line-height: 35px;
            span {
              float: left;
            }
            i {
              float: right;
              margin-top: 10px;
              transition: all 0.4s;
              font-size: 14px;
            }
          }
          .modules_name.on {
            border-radius: 4px 4px 0 0;
            i {
              transform: rotate(-180deg);
            }
          }
          .modules_list {
            width: 100%;
            .modulesLi {
              background-color: rgb(248, 248, 250);
              padding: 3px;
              .modulesSingle {
                width: 82px;
                height: 82px;
                padding: 9px 0;
                box-sizing: border-box;
                text-align: center;
                cursor: pointer;
                margin: 3px;
                background-color: #fff;
                img {
                  width: 39px;
                  height: 39px;
                  margin: 0 atuo;
                  margin-bottom: 4px;
                }
                div {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    .componentModal.active {
      left: 106px;
    }
    .overall {
      // width: 40%;
      width: 100%;
      background-color: #f2f4f7;
      max-height: 100%;
      overflow-y: auto;
      min-width: 1244px;
      padding: 0 !important;
      .situation {
        font-size: 12px;
        color: #333333;
        padding-top: 122px;
        img {
          object-fit: cover;
          display: block;
          width: 100%;
          height: 100%;
        }
        .situation_head {
          margin: 0 auto;
          .situation_header {
            width: 1190px;
            height: 32px;
            background-color: #f5f5f5;
            color: #666666;
            margin: 0 auto;
            display: flex;
            align-items: center;
            .top-left {
              height: 32px;
              display: flex;
              align-items: center;
              img {
                height: 12px;
                width: 12px;
              }
            }
            .top-right {
              margin-left: auto;
              display: flex;
              height: 32px;
              align-items: center;
              .xia {
                font-size: 12px;
                display: block;
              }
              li {
                height: 32px;
                display: flex;
                padding: 0 12px;
                align-items: center;
                box-sizing: border-box;
                cursor: pointer;
                position: relative;
                &::before {
                  content: "";
                  width: 1px;
                  height: 10px;
                  background: #d9d9d9;
                  position: absolute;
                  top: 12px;
                  right: 0px;
                }
                div:first-child {
                  margin-left: 16px;
                }
              }
              .login {
                div {
                  cursor: pointer;
                  color: #ff0e25;
                  text-decoration: none;
                  outline: 0;
                }
                .ttshop_lodings {
                  font-size: 12px;
                  color: #999;
                  margin-right: 8px;
                  transition: 0.5s;
                  -moz-transition: 0.5s;
                  -webkit-transition: 0.5s;
                  -o-transition: 0.5s;
                  cursor: pointer;
                }
              }
              .top_last {
                transition: 0.5s;
                -moz-transition: 0.5s;
                -webkit-transition: 0.5s;
                -o-transition: 0.5s;
                &:hover {
                  color: #ff0e25;
                  background-color: #fff;
                }
              }
            }
          }
          .index-search {
            height: 112px;
            background-color: #fff;
            width: 100%;
            .search-box {
              display: flex;
              height: 112px;
              padding-top: 32px;
              box-sizing: border-box;
              .logo {
                display: block;
                width: 187px;
                height: 55px;
                img {
                  width: auto;
                  height: auto;
                  object-fit: fill;
                  display: block;
                  margin: 0 auto;
                }
              }

              .search-c {
                margin-left: 113px;

                .search-item {
                  display: flex;

                  .box {
                    width: 548px;
                    height: 40px;
                    border: 2px solid #ff0008;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    position: relative;

                    .img {
                      height: 16px;
                      width: 16px;
                      margin-left: 12px;
                      margin-right: 4px;
                    }

                    .search-input {
                      width: 100%;
                      height: 36px;
                      border: 0 none;
                      // box-sizing: border-box;
                    }
                    .history {
                      position: absolute;
                      left: 0px;
                      top: 40px;
                      z-index: 999;
                      width: 548px;
                      transition: all 0.3s;
                      background: #fff;
                      box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.2);
                      border: 1px solid #ccc;
                      .history_title {
                        padding: 5px 10px;
                        font-size: 14px;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .title_name {
                          color: #005aa0;
                        }
                        .title_toast {
                          color: #ccc;
                        }
                        .title_del {
                          color: #005aa0;
                        }
                      }
                      .historyActive {
                        background: rgba(0, 0, 0, 0.1);
                      }
                      .del_past {
                        padding: 5px 0;
                        margin: 0 10px;
                        text-align: right;
                        font-size: 16px;
                        cursor: pointer;
                        border-top: solid 1px #ccc;
                      }
                    }
                  }

                  .search-btn {
                    width: 90px;
                    height: 40px;
                    background-color: #ff0008;
                    color: #fff;
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  }
                }

                .search-default {
                  display: flex;
                  margin-top: 8px;

                  li {
                    margin-right: 8px;
                    color: #b3b3b3;

                    span {
                      font-size: 12px;
                      color: #b3b3b3;
                      transition: 0.5s;
                      -moz-transition: 0.5s;
                      -webkit-transition: 0.5s;
                      -o-transition: 0.5s;
                      cursor: pointer;

                      &:hover {
                        color: #ff0e25;
                      }
                    }
                  }
                }
              }

              .cat {
                width: 132px;
                height: 40px;
                background: #F8F8F8;
                border-radius: 3px;
                border: 1px solid #E6E6E6;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ED2A20;
                margin-left: 32px;
                font-size: 18px;
                font-weight: 400;
                color: #ED2A20;
                line-height: 22px;
                cursor: pointer;
                position: relative;

                span {
                  position: absolute;
                  top: 3px;
                  right: 12px;
                  display: inline-block;
                  padding: 1px 3px;
                  font-size: 12px;
                  line-height: 12px;
                  color: #fff;
                  background-color: #e1251b;
                  border-radius: 7px;
                  min-width: 12px;
                  text-align: center;
                }

                .xia {
                  width: 18px;
                  height: 17px;
                  margin-right: 8px;
                }
              }
            }
          }
          .index-nav {
            position: relative;
            height: 40px;
            width: 100%;
            background-color: #fff;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
            background-color: rgb(255, 255, 255);
            color: rgb(51, 51, 51);
            overflow: hidden;
            .nav-box {
              display: flex;
              height: 40px;
              line-height: 40px;
              position: relative;
              .nav-all {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 600;
                width: 190px;
                padding-left: 20px;
                box-sizing: border-box;
                position: relative;
                &:hover {
                  .nav_classify {
                    display: block;
                  }
                }
                .img-all {
                  width: 14px;
                  height: 12px;
                  margin-right: 8px;
                  vertical-align: middle;

                  span {
                    display: block;
                    margin-top: 3px;
                  }

                  span:after {
                    content: "";
                    display: block;
                    width: 14px;
                    height: 0;
                    border-top: 2px solid;
                  }

                  span:first-child {
                    margin-top: 0;
                  }
                }

                .img-xia {
                  /* width: 9px;
					height: 6px;
					margin-left: 4px;
					vertical-align: middle; */
                  content: "";
                  width: 0;
                  height: 0;
                  margin-left: 4px;
                  border-top: 4px solid;
                  border-right: 4px solid transparent;
                  border-left: 4px solid transparent;
                }
              }
              .nav-list {
                display: flex;
                font-weight: 600;
                margin-left: 34px;
                padding: 0;
                margin-bottom: 0;
                list-style: none;
                flex-wrap: wrap;
                overflow: hidden;
                li {
                  color: rgb(51, 51, 51);
                  margin-right: 40px;
                  font-size: 16px;
                  font-weight: 600;
                  cursor: pointer;
                  transition: 0.5s;
                  -moz-transition: 0.5s;
                  -webkit-transition: 0.5s;
                  -o-transition: 0.5s;
                }
              }
            }
            .module-hover-show {
              cursor: pointer;
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.3);
              visibility: hidden;
              opacity: 0;
              transition: all 0.3s;
              display: flex;
              justify-content: flex-end;
            }

            &:hover {
              .module-hover-show {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
        .sortList {
          // width: 1190px;
          width: 1200px;
          margin: 0px auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-bottom: 50px;
          .module-box {
            position: relative;
            overflow: hidden;
            // min-width: 100vw;
            // margin-left: calc((1190px - 100vw) / 2);
          }
          .block-empty {
            width: 100%;
            margin-top: 280px;
            .empty_con {
              text-align: center;
              .empty_title {
                color: rgb(187, 187, 187);
                margin-top: 21px;
              }
            }
          }
        }
        .footer {
          position: relative;
          overflow: hidden;
          .foot_box {
            margin-top: 20px;
            .foot-t {
              width: 100%;
              background-color: #fafafa;
              .foot_wrap {
                width: 1190px;
                margin: 0 auto;
                position: relative;
                .foot_ul {
                  height: 108px;
                  display: flex;
                  justify-content: space-between;
                  margin: 0;
                  padding: 0;
                  li {
                    font-size: 20px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                    img {
                      display: block;
                      width: 40px;
                      height: 40px;
                      margin-right: 19px;
                      object-fit: cover;
                    }
                  }
                }
              }
            }
          }
          &:hover {
            .module-hover-show {
              visibility: visible;
              opacity: 1;
            }
          }
          .module-hover-show {
            cursor: pointer;
            position: absolute;
            z-index: 1;
            top: 20px;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
  .dialog {
    max-height: 620px;
    .template_container {
      height: 620px;
      max-height: 620px;
      .template_wrap {
        height: calc(100% - 50px);
        overflow-y: auto;
        padding: 24px 20px 24px 24px;
      }
    }
  }
}
</style>