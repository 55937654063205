<template>
  <div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">轮播图样式</div>
      <div class="diy-group-content">
        <el-radio-group
          v-model="banner.param.style"
          class="diy-radio-group d-flex align-items-center flex-wrap"
        >
          <el-radio :label="0">全屏样式 (推荐尺寸1920*480)</el-radio>
          <el-radio :label="1">半屏样式 (推荐尺寸770*480)</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">轮播图样式</div>
      <div class="diy-group-content">
        <el-radio-group
          v-model="banner.param.fill"
          class="diy-radio-group d-flex align-items-center flex-wrap"
        >
          <el-radio :label="0">填充</el-radio>
          <el-radio :label="1">留白</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex"></div>
      <div class="diy-group-content">
        <el-table :data="banner.param.list" style="width: 100%">
          <el-table-column label="图片" min-width="135">
            <template slot-scope="scope">
              <div v-if="scope.row.pic_url" class="picurl"  @click.stop="linkChange('',scope,'img')">
                <img :src="scope.row.pic_url" alt="" />
              </div>
              <div v-else class="uploadImg" @click.stop="linkChange('',scope,'img')">
                <span>+</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="链接" min-width="260">
            <template slot-scope="scope">
              <div class="title">
                <el-input
                  v-model="scope.row.page_url"
                  readonly
                  @click.native="linkChange($event, scope,'link')"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-link"
                    style="color: #4877fb; font-size: 16px; line-height: 32px"
                  ></i>
                </el-input>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < banner.param.list.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="打开方式" min-width="102">
            <template slot-scope="scope">
              <div class="title">
                <el-select
                  v-model="scope.row.open_type"
                  @change="openChange($event, scope)"
                >
                  <el-option label="本窗口" value="0"></el-option>
                  <el-option label="新窗口" value="1"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="handleTable(scope)">
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="appendNav"
        >
          <span>+</span>添加导航
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "carousel",
  props:['param'],
  data() {
    return {
      banner: this.param,
      openIndex: -1,
    };
  },
  methods: {
    // 删除
    handleTable(scope) {
      let index = scope.$index;
      this.banner.param.list.splice(index, 1);
      this.pubilcCom();
    },
    // 往上挪一位
    moreUp(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.banner.param.list[index - 1])
      );
      this.$set(
        this.banner.param.list,
        index - 1,
        this.banner.param.list[index]
      );
      this.$set(this.banner.param.list, index, options);
      this.pubilcCom();
    },
    // 往下挪一位
    moreDown(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.banner.param.list[index + 1])
      );
      this.$set(
        this.banner.param.list,
        index + 1,
        this.banner.param.list[index]
      );
      this.$set(this.banner.param.list, index, options);
      this.pubilcCom();
    },
    // 打开方式
    openChange(val, scope) {
      let index = scope.$index;
      this.banner.param.list[index].open_type = val;
      this.pubilcCom();
    },
    linkChange(val,scope,type){
      this.openIndex=scope.$index;
      this.$emit("navchange",type)
    },
    // 新增导航
    appendNav() {
      let data = {
        pic_url: "",
        page_url: "",
        open_type: "0",
      };
      this.banner.param.list.push(data);
      this.pubilcCom()
    },
    // 往父级传值
    pubilcCom() {
      let list=this.banner.param.list;
      this.$emit('navchange','byvalue',list)
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    .diy-radio-group {
      min-height: 32px;
    }
    .picurl {
      width: 53px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .uploadImg {
      width: 50px;
      height: 100%;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      span {
        display: block;
        font-size: 24px;
        color: #f0f0f0;
      }
    }
    /deep/.el-table {
      background: #f0f0f0;
      .el-table--scrollable-x .el-table__body-wrapper {
        background: #fff !important;
      }
      .el-table__body-wrapper {
        background: #fff;
      }
      /deep/.el-table__body-wrapper.is-scrolling-left {
        background: #fff !important;
      }
    }
    .addNav {
      width: 100%;
      height: 32px;
      line-height: 30px;
      border: 1px dashed #ccc;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-top: 24px;
      cursor: pointer;
    }
  }
}
.title {
  height: 32px;
  line-height: 32px;
  /deep/.el-input {
    width: 100%;
    height: 32px;
    line-height: 32px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input__suffix{
      .el-input__suffix-inner{
        .el-input__icon{
          line-height: 32px;
        }
      }
    }
  }
  /deep/.el-select{
    width: 100%;
  }
}
.dy_more{
  display: inline-block;
    width: 28px;
    height: 32px;
    cursor: pointer;
    vertical-align: sub;
    i{
      line-height: 32px;
    }
}
.handle{
  cursor: pointer;
  i{
    font-size: 20px;
  }
}
.dy_more {
  display: inline-block;
  width: 28px;
  height: 32px;
  cursor: pointer;
  vertical-align: sub;
  i {
    line-height: 32px;
  }
}
</style>