<template>
  <div>
    <!-- 个性商品 -->
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">标题</div>
      <div class="diy-group-content">
        <div class="check-color d-flex align-items-center diy-group-content">
          <el-input
            placeholder="请输入标题"
            v-model="character.param.title"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">文本</div>
      <div class="diy-group-content">
        <div class="check-color d-flex align-items-center diy-group-content">
          <el-input
            placeholder="请输入文本"
            v-model="character.param.text"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy_content">
        <el-table :data="character.param.goodslist" style="width: 100%">
          <el-table-column label="商品信息" min-width="320">
            <template slot-scope="scope">
              <div class="shoplister d-flex align-items-center">
                <div class="shopimg">
                  <img :src="scope.row.cover_pic" alt="" />
                </div>
                <div class="shoptext">
                  <h6>{{ scope.row.name }}</h6>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="价格" align="center" min-width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.price }}积分</div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < character.param.goodslist.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="handleTable(scope)">
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="appendgood" v-if="character.param.goodslist.length<5"
        >
          <span>+</span>添加商品
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "character",
  props: ["param"],
  data() {
    return {
      character: this.param,
    };
  },
  methods: {
    // 往上挪一位
    moreUp(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.character.param.goodslist[index - 1])
      );
      this.$set(
        this.character.param.goodslist,
        index - 1,
        this.character.param.goodslist[index]
      );
      this.$set(this.character.param.goodslist, index, options);

      this.pubilcCom();
    },
    moreDown(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.character.param.goodslist[index + 1])
      );
      this.$set(
        this.character.param.goodslist,
        index + 1,
        this.character.param.goodslist[index]
      );
      this.$set(this.character.param.goodslist, index, options);
      this.pubilcCom();
    },
    handleTable(scope) {
      let index = scope.$index;
      this.character.param.goodslist.splice(index, 1);
      this.pubilcCom();
    },
    appendgood(){
        this.$emit("change",'goods');
    },
    pubilcCom() {
      let data = this.character;
      this.$emit("change",'', data);
    },
  },
};
</script>
<style lang="scss" scoped>

.shoplister {
    width: 100%;
}

.shopimg {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 12px;
}

.shopimg img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.shoptext {
    width: 100%;
    float: left;
    width: calc(100% - 62px);
}

.shoptext h6 {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #333;
    font-weight: 500;
    line-height: 22px;
    max-height: 44px;
}
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    .diy-radio-group {
      min-height: 32px;
    }
    .picurl {
      width: 53px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .uploadImg {
      width: 50px;
      height: 100%;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      span {
        display: block;
        font-size: 24px;
        color: #f0f0f0;
      }
    }
    /deep/.el-table {
      background: #f0f0f0;
      .el-table--scrollable-x .el-table__body-wrapper {
        background: #fff !important;
      }
      .el-table__body-wrapper {
        background: #fff;
      }
      /deep/.el-table__body-wrapper.is-scrolling-left {
        background: #fff !important;
      }
    }
    .addNav {
      width: 100%;
      height: 32px;
      line-height: 30px;
      border: 1px dashed #ccc;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-top: 24px;
      cursor: pointer;
    }
  }
}
.check-color {
  .slider_text {
    width: 210px;
  }
  .check_color {
    min-width: 80px;
    height: 24px;
    background: #f0f0f0;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    border: none;
    outline: none;
    padding: 0 8px;
    margin-left: 18px;
    line-height: 24px;
  }
}

.diy_content {
  width: 100%;
}
.diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    /deep/.el-input {
      width: 280px;
      height: 32px;
      line-height: 32px;
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .diy-radio-group {
      min-height: 32px;
    }
  }
  
.addNav {
  width: 100%;
  height: 32px;
  line-height: 30px;
  border: 1px dashed #ccc;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
  cursor: pointer;
}
.handle{
  cursor: pointer;
  i{
    font-size: 20px;
  }
}
.dy_more {
  display: inline-block;
  width: 28px;
  height: 32px;
  cursor: pointer;
  vertical-align: sub;
  i {
    line-height: 32px;
  }
}
</style>