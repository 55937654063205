<template>
  <div>
    <div class="d-flex diy-row">
      <div class="d-flex diy-group-title">背景颜色</div>
      <div class="diy-group-content">
        <el-radio-group
          v-model="headetail.background.back_style"
          @change="headRadioChange"
          class="diy-radio-group d-flex align-items-center"
        >
          <el-radio :label="0">默认</el-radio>
          <el-radio :label="1">自定义</el-radio>
        </el-radio-group>
        <div
          v-if="headetail.background.back_style == 0"
          class="d-flex align-items-center back-color-change"
        >
          <div
            class="border-block"
            v-for="(item, index) in colorList"
            :class="[colorActive == index ? 'active' : '']"
            @click="selectBackground(index)"
          >
            <div :style="{ backgroundColor: item.background }"></div>
          </div>
        </div>
        <div
          v-else-if="headetail.background.back_style == 1"
          class="check-color d-flex align-items-center"
        >
          <el-color-picker
            v-model="backgrounfColor"
            @change="backgroundColor" class="color_picker"
          ></el-color-picker>
          <div class="check_color">{{ backgrounfColor }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex diy-row">
      <div class="d-flex diy-group-title">字体颜色</div>
      <div class="diy-group-content">
        <div class="check-color d-flex align-items-center">
          <el-color-picker
            v-model="headetail.font_color"
            @change="fontChange"
          ></el-color-picker>
          <div class="check_color">{{ headetail.font_color }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex diy-row">
      <div class="d-flex diy-group-title"></div>
      <div class="diy-group-content">
        <el-table :data="headetail.list" style="width: 100%">
          <el-table-column label="标题" min-width="136">
            <template slot-scope="scope">
              <div class="title">
                <el-input
                  v-model="scope.row.title"
                  @change="titleInput($event, scope)"
                  placeholder="请输入标题名称"
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="链接" min-width="260">
            <template slot-scope="scope">
              <div class="title">
                <el-input
                  v-model="scope.row.page_url"
                  readonly
                  @click.native="linkChange($event, scope)"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-link"
                    style="color: #4877fb; font-size: 16px; line-height: 32px"
                  ></i>
                </el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < headetail.list.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="打开方式" min-width="102">
            <template slot-scope="scope">
              <div class="title">
                <el-select
                  v-model="scope.row.open_type"
                  @change="openChange($event, scope)"
                >
                  <el-option label="本窗口" value="0"></el-option>
                  <el-option label="新窗口" value="1"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="handleTable(scope)">
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="appendNav"
        >
          <span>+</span>添加导航
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headerNav",
  props: ["headUrl"],
  data() {
    return {
      headRadio: 0,
      colorList: [
        {
          color: "#FFFFFF",
          background: "rgb(255, 255, 255)",
        },
        {
          color: "#DD2727",
          background: "rgb(221, 39, 39)",
        },
        {
          color: "#FFC402",
          background: "rgb(255, 196, 2)",
        },
        {
          color: "#89B13A",
          background: "rgb(137, 177, 58)",
        },
        {
          color: "#35A981",
          background: "rgb(53, 169, 129)",
        },
        {
          color: "#4877FB",
          background: "rgb(72, 119, 251)",
        },
      ],
      colorActive: 0,
      backgrounfColor: "#FFFFFF",
      fontColor: "#333333",
      navList: [],
      openIndex: -1,
      headetail: this.headUrl,
    };
  },
  created() {
    if (this.headUrl.background) {
      if (this.headUrl.background.back_style == 0) {
        this.colorActive = this.colorList.findIndex(
          (item) => item.color == this.headUrl.background.color
        );
      } else if (this.headUrl.background.back_style == 1) {
        this.backgrounfColor = this.headUrl.background.color;
      }
    }
  },
  methods: {
    headRadioChange(e) {
      this.pubilcCom();
    },
    // 默认选择背景颜色
    selectBackground(index) {
      this.colorActive = index;
      this.pubilcCom();
    },
    // 自定义设置背景颜色
    backgroundColor(val) {
      this.backgrounfColor = val;
      this.pubilcCom();
    },
    // 字体颜色
    fontChange(val) {
      this.headetail.font_color = val;
      this.pubilcCom();
    },
    // 新增导航
    appendNav() {
      let data = {
        title: "",
        page_url: "",
        open_type: "0",
      };
      this.headetail.list.push(data);
      this.pubilcCom();
    },
    // 标题输入
    titleInput(val, scope) {
      let index = scope.$index;
      this.headetail.list[index].title = val;
      this.pubilcCom();
    },
    // 链接选择
    linkChange(val, scope) {
      this.openIndex = scope.$index;
      this.$emit("navchange");
    },
    // 删除table
    handleTable(scope) {
      let index = scope.$index;
      this.headetail.list.splice(index, 1);
      this.pubilcCom();
    },
    // 往上挪一位
    moreUp(scope) {
      let index = scope.$index;
      var options = JSON.parse(JSON.stringify(this.headetail.list[index - 1]));
      this.$set(this.headetail.list, index - 1, this.headetail.list[index]);
      this.$set(this.headetail.list, index, options);
      this.pubilcCom();
    },
    // 往下挪一位
    moreDown(scope) {
      let index = scope.$index;
      var options = JSON.parse(JSON.stringify(this.headetail.list[index + 1]));
      this.$set(this.headetail.list, index + 1, this.headetail.list[index]);
      this.$set(this.headetail.list, index, options);
      this.pubilcCom();
    },
    // 打开方式
    openChange(val, scope) {
      let index = scope.$index;
      this.headetail.list[index].open_type = val;
      this.pubilcCom();
    },
    // 往父级传值
    pubilcCom() {
      let data = this.headetail;
      if (this.headetail.background.back_style == 0) {
        data.background.color = this.colorList[this.colorActive].background;
      } else if (this.headetail.background.back_style == 1) {
        data.background.color = this.backgrounfColor;
      }
      this.$emit("change", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.diy-row {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    .diy-radio-group {
      min-height: 32px;
    }
    .back-color-change {
      min-height: 32px;
      .border-block {
        width: 56px;
        height: 24px;
        background: #f0f0f0;
        padding: 4px;
        cursor: pointer;
        margin-left: 8px;
        > div:first-child {
          width: 100%;
          height: 100%;
        }
      }

      .border-block.active {
        border: 1px solid #4877fb;
        position: relative;
      }
      .border-block:first-child {
        margin: 0;
      }
    }
    .check-color {
      .check_color {
        width: 80px;
        height: 24px;
        background: #f0f0f0;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        border: none;
        outline: none;
        padding: 0 8px;
        margin-left: 8px;
        line-height: 24px;
      }
    }
    /deep/.el-table {
      background: #f0f0f0;
      .el-table--scrollable-x .el-table__body-wrapper {
        background: #fff !important;
      }
      .el-table__body-wrapper {
        background: #fff;
      }
      /deep/.el-table__body-wrapper.is-scrolling-left {
        background: #fff !important;
      }
    }
    .addNav {
      width: 100%;
      height: 32px;
      line-height: 30px;
      border: 1px dashed #ccc;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-top: 24px;
      cursor: pointer;
    }
  }
}
.title {
  height: 32px;
  line-height: 32px;
  /deep/.el-input {
    width: 100%;
    height: 32px;
    line-height: 32px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input__suffix {
      .el-input__suffix-inner {
        .el-input__icon {
          line-height: 32px;
        }
      }
    }
  }
  /deep/.el-select {
    width: 100%;
  }
}
.dy_more {
  display: inline-block;
  width: 28px;
  height: 32px;
  cursor: pointer;
  vertical-align: sub;
  i {
    line-height: 32px;
  }
}
.handle {
  cursor: pointer;
  i {
    font-size: 20px;
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #fff;
}
/deep/.el-input{
  width: 100%;
}

</style>
<style>
.el-color-picker__panel .el-color-dropdown__btns .el-color-dropdown__value .el-input{
  width: 100%;
}
.el-color-picker__panel .el-color-dropdown__btns .el-button{
  padding: 0;
}
.el-color-picker__panel .el-color-dropdown__btns .is-plain{
  padding: 7px 15px;
}
</style>