<template>
  <div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">标题</div>
      <div class="diy-group-content">
        <el-input
          v-model="hotDetail.param.title"
          placeholder="请输入标题"
          @change="titleInput"
        ></el-input>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex"></div>
      <div class="diy_content">
        <el-table :data="hotDetail.param.cat_list" style="width: 100%">
          <el-table-column label="分类名称" min-width="400">
            <template slot-scope="scope">
              <div class="classification">
                <div class="class_title">{{ scope.row.name }}</div>
                <div
                  class="shoplister d-flex align-items-center"
                  v-for="(item, index) in scope.row.goodslist"
                  :key="index"
                >
                  <div class="shopimg">
                    <img :src="item.cover_pic" alt="" />
                  </div>
                  <div class="shoptext">
                    <h6>{{ item.name }}</h6>
                  </div>
                </div>

                <div class="class_add" @click="board('good', scope)">
                  <div
                    class="add_good d-flex align-items-center justify-content-center"
                  >
                    <span>+</span>
                  </div>
                  <div class="good_title">添加商品</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="adjust">
               
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < hotDetail.param.cat_list.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
              <template v-if="scope.row.goodslist">
                <div v-for="(item,index) in scope.row.goodslist" :key="index" class="d-flex align-items-center adjust_good">
                  <div class="dy_more" v-show="index>0" @click="goodUp(scope,index)">
                    <i class="el-icon-top"></i>
                  </div>
                  <div class="dy_more" v-show="index<scope.row.goodslist.length-1" @click.stop="goodDown(scope,index)">
                    <i class="el-icon-bottom"></i>
                  </div>
                </div>
              </template>
              <div class="adjust_kong"></div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="handleTable(scope)">
                <i class="el-icon-delete"></i>
              </div>
              <template v-if="scope.row.goodslist">
                <div v-for="(item,index) in scope.row.goodslist" :key="index" class="d-flex align-items-center adjust_good">
                  <div class="handle" @click.stop="delgood(scope,index)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </template>
              <div class="adjust_kong"></div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="board('class')"
        >
          <span>+</span>添加分类
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "hot",
  props: ["param"],
  data() {
    return {
      hotDetail: this.param,
      goodIndex: 0,
      classId: "",
    };
  },
  mounted(){
    //console.log(this.hotDetail)
  },
  methods: {
    titleInput(value) {
      this.hotDetail.param.title = value;
      this.pubilcCom();
    },
    board(type, scope) {
      let data = {};
      if (scope) {
        this.goodIndex = scope.$index;
        (this.classId = scope.row.id), (data.class_id = scope.row.ids);
      }
      this.$emit("change", type, data);
    },
    // 往上挪一位
    moreUp(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.hotDetail.param.cat_list[index - 1])
      );
      this.$set(
        this.hotDetail.param.cat_list,
        index - 1,
        this.hotDetail.param.cat_list[index]
      );
      this.$set(this.hotDetail.param.cat_list, index, options);

      this.pubilcCom();
    },
    // 往下挪一位
    moreDown(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.hotDetail.param.cat_list[index + 1])
      );
      this.$set(
        this.hotDetail.param.cat_list,
        index + 1,
        this.hotDetail.param.cat_list[index]
      );
      this.$set(this.hotDetail.param.cat_list, index, options);
      this.pubilcCom();
    },
    goodUp(scope,index){
      let ind=scope.$index;
      let options=JSON.parse(JSON.stringify(this.hotDetail.param.cat_list[ind].goodslist[index-1]));
      this.$set(
        this.hotDetail.param.cat_list[ind].goodslist,
        index - 1,
        this.hotDetail.param.cat_list[ind].goodslist[index]
      );
      this.$set(this.hotDetail.param.cat_list[ind].goodslist, index, options);
      this.pubilcCom();

    },
    goodDown(scope,index){
      let ind=scope.$index;
      let options=JSON.parse(JSON.stringify(this.hotDetail.param.cat_list[ind].goodslist[index+1]));
      this.$set(
        this.hotDetail.param.cat_list[ind].goodslist,
        index + 1,
        this.hotDetail.param.cat_list[ind].goodslist[index]
      );
      this.$set(this.hotDetail.param.cat_list[ind].goodslist, index, options);
      this.pubilcCom();
    },

    handleTable(scope) {
      let index = scope.$index;
      this.hotDetail.param.cat_list.splice(index, 1);
      this.pubilcCom();
    },
    delgood(scope,index){
      let ind=scope.$index;
      this.hotDetail.param.cat_list[ind].goodslist.splice(index,1);
      this.pubilcCom()
    },
    pubilcCom() {
      let data = this.hotDetail;
      this.$emit("change", "", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    /deep/.el-input {
      width: 280px;
      height: 32px;
      line-height: 32px;
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .diy-radio-group {
      min-height: 32px;
    }
    .advertising {
      width: 242px;
      height: 116px;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      display: inline-block;
      overflow: hidden;
      line-height: 116px;
      .uploadImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .upload_img {
        width: 100%;
        height: 100%;
        span {
          display: block;
          font-size: 24px;
          color: #f0f0f0;
        }
      }
    }
    .advertising_toast {
      font-size: 12px;
      color: #666;
      margin-left: 10px;
      line-height: 116px;
    }
  }
  .diy_content {
    width: 100%;
  }
}
.addNav {
  width: 100%;
  height: 32px;
  line-height: 30px;
  border: 1px dashed #ccc;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
  cursor: pointer;
}
.classification {
  
  .shoplister{
    padding:0 10px;
    margin-top: 10px;
  }
  .class_add {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    padding: 0 10px;
    .add_good {
      width: 32px;
      height: 32px;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      span {
        display: block;
        font-size: 24px;
        color: #f0f0f0;
      }
    }
    .good_title {
      font-size: 12px;
      color: #666;
      margin-left: 10px;
      line-height: 32px;
    }
  }
}
.adjust{
  height: 23px;
}
.adjust_good{
  height: 50px;
  margin-top: 10px;
  cursor: pointer;
}
.adjust_kong{
  height: 32px;
  margin-top: 10px;
}
.handle{
  cursor: pointer;
}

.shopimg {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 12px;
}

.shopimg img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.shoptext {
    width: 100%;
    float: left;
    width: calc(100% - 62px);
}

.shoptext h6 {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #333;
    font-weight: 500;
    line-height: 22px;
    max-height: 44px;
}
</style>