<template>
  <div>
    <!-- 楼层 -->
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">标题</div>
      <div class="diy-group-content">
        <div class="check-color d-flex align-items-center diy-group-content">
          <el-input
            placeholder="请输入标题"
            v-model="character.param.title" @change="titleInput"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">文本</div>
      <div class="diy-group-content">
        <div class="check-color d-flex align-items-center diy-group-content">
          <el-input
            placeholder="请输入标题"
            v-model="character.param.text" @change="textInput"
          ></el-input>
        </div>
      </div>
    </div>

    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">热销</div>
      <div class="diy-group-content d-flex align-items-center">
        <div class="title">
          <el-input
            v-model="character.param.hot_url"
            readonly
            placeholder="请选择热销链接"
            @click.native="board('hot_link')"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-link"
              style="color: #4877fb; font-size: 16px; line-height: 32px"
            ></i>
          </el-input>
        </div>
      </div>
    </div>

    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex"></div>
      <div class="diy_content">
        <el-table :data="character.param.hot_list">
          <el-table-column label="商品信息" min-width="320">
            <template slot-scope="scope">
              <div class="shoplister d-flex align-items-center">
                <div class="shopimg">
                  <img :src="scope.row.cover_pic" alt="" />
                </div>
                <div class="shoptext">
                  <h6>{{ scope.row.name }}</h6>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="价格" align="center" min-width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.price }}积分</div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="hotUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < character.param.hot_list.length - 1"
                  class="dy_more"
                  @click.stop="hotDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="hothandle(scope)">
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="board('hot_good')"
          v-if="character.param.hot_list.length < 5"
        >
          <span>+</span>添加商品
        </div>
      </div>
    </div>
    <div class="carousel_wrap a-flex">
      <div class="diy_content">
        <el-table
          :data="character.param.cat_list"
          row-key="id"
          style="width: 100%"
        
        >
          <el-table-column label="分类" min-width="200">
            <template slot-scope="scope">
              <div class="classification">
                <div class="class_title">{{ scope.row.name }}</div>
                <div
                  class="shoplister d-flex align-items-center"
                  v-for="(item, index) in scope.row.goodslist"
                  :key="index"
                >
                  <div class="shopimg">
                    <img :src="item.cover_pic" alt="" />
                  </div>
                  <div class="shoptext">
                    <h6>{{ item.name }}</h6>
                  </div>
                </div>

                <div class="class_add" @click="board('good', scope)" v-if="scope.row.goodslist.length<8">
                  <div
                    class="add_good d-flex align-items-center justify-content-center"
                  >
                    <span>+</span>
                  </div>
                  <div class="good_title">添加商品</div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="scope.$index < character.param.cat_list.length - 1"
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
              
              <template v-if="scope.row.goodslist">
                <div v-for="(item,index) in scope.row.goodslist" :key="index" class="d-flex align-items-center adjust_good">
                  <div class="dy_more" v-show="index>0" @click="goodUp(scope,index)">
                    <i class="el-icon-top"></i>
                  </div>
                  <div class="dy_more" v-show="index<scope.row.goodslist.length-1" @click.stop="goodDown(scope,index)">
                    <i class="el-icon-bottom"></i>
                  </div>
                </div>
              </template>
              <div class="adjust_kong"></div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="handleTable(scope)">
                <i class="el-icon-delete"></i>
              </div>
              <template v-if="scope.row.goodslist">
                <div v-for="(item,index) in scope.row.goodslist" :key="index" class="d-flex align-items-center adjust_good">
                  <div class="handle" @click.stop="delgood(scope,index)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </template>
              <div class="adjust_kong"></div>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="appendgood"
        >
          <span>+</span>添加分类
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "floor",
  props: ["param"],
  data() {
    return {
      character: this.param,
      tem_type: "",
      goodIndex:0,
    };
  },
  methods: {
    titleInput(value) {
      this.character.param.title = value;
      this.pubilcCom();
    },
    textInput(value){
      this.character.param.text = value;
      this.pubilcCom();
    },
    board(type,scope) {
      let data = {};
      if (scope) {
        this.goodIndex = scope.$index;
         data.class_id = scope.row.ids;
      }
      this.tem_type = type;
      this.$emit("floor", type,data);
    },
    hotUp(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.character.param.hot_list[index - 1])
      );
      this.$set(
        this.character.param.hot_list,
        index - 1,
        this.character.param.hot_list[index]
      );
      this.$set(this.character.param.hot_list, index, options);
      this.pubilcCom();
    },

    hotDown(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.character.param.hot_list[index + 1])
      );
      this.$set(
        this.character.param.hot_list,
        index + 1,
        this.character.param.hot_list[index]
      );
      this.$set(this.character.param.hot_list, index, options);
      this.pubilcCom();
    },
    hothandle(scope) {
      let index = scope.$index;
      this.character.param.hot_list.splice(index, 1);
      this.pubilcCom();
    },
    // 往上挪一位
    moreUp(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.character.param.cat_list[index - 1])
      );
      this.$set(
        this.character.param.cat_list,
        index - 1,
        this.character.param.cat_list[index]
      );
      this.$set(this.character.param.cat_list, index, options);

      this.pubilcCom();
    },
    moreDown(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.character.param.cat_list[index + 1])
      );
      this.$set(
        this.character.param.cat_list,
        index + 1,
        this.character.param.cat_list[index]
      );
      this.$set(this.character.param.cat_list, index, options);
      this.pubilcCom();
    },
    handleTable(scope) {
      let index = scope.$index;
      this.character.param.cat_list.splice(index, 1);
      this.pubilcCom();
    },
    appendgood() {
      this.tem_type = '';
      this.$emit("floor",'class');
    },
    pubilcCom() {
      let data = this.character;
      this.$emit("change", "", data);
    },
    goodUp(scope,index){
      let ind=scope.$index;
      let options=JSON.parse(JSON.stringify(this.character.param.cat_list[ind].goodslist[index-1]));
      this.$set(
        this.character.param.cat_list[ind].goodslist,
        index - 1,
        this.character.param.cat_list[ind].goodslist[index]
      );
      this.$set(this.character.param.cat_list[ind].goodslist, index, options);
      this.pubilcCom();

    },
    goodDown(scope,index){
      let ind=scope.$index;
      let options=JSON.parse(JSON.stringify(this.character.param.cat_list[ind].goodslist[index+1]));
      this.$set(
        this.character.param.cat_list[ind].goodslist,
        index + 1,
        this.character.param.cat_list[ind].goodslist[index]
      );
      this.$set(this.character.param.cat_list[ind].goodslist, index, options);
      this.pubilcCom();
    },
    
    delgood(scope,index){
      let ind=scope.$index;
      this.character.param.cat_list[ind].goodslist.splice(index,1);
      this.pubilcCom()
    },
  },
};
</script>
<style lang="scss" scoped>

.shoplister {
    width: 100%;
}

.shopimg {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 12px;
}

.shopimg img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.shoptext {
    width: 100%;
    float: left;
    width: calc(100% - 62px);
}

.shoptext h6 {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #333;
    font-weight: 500;
    line-height: 22px;
    max-height: 44px;
}
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    .diy-radio-group {
      min-height: 32px;
    }
    .picurl {
      width: 53px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .uploadImg {
      width: 50px;
      height: 100%;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      span {
        display: block;
        font-size: 24px;
        color: #f0f0f0;
      }
    }
    /deep/.el-table {
      background: #f0f0f0;
      .el-table--scrollable-x .el-table__body-wrapper {
        background: #fff !important;
      }
      .el-table__body-wrapper {
        background: #fff;
      }
      /deep/.el-table__body-wrapper.is-scrolling-left {
        background: #fff !important;
      }
    }
    .addNav {
      width: 100%;
      height: 32px;
      line-height: 30px;
      border: 1px dashed #ccc;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-top: 24px;
      cursor: pointer;
    }
  }
}
.check-color {
  .slider_text {
    width: 210px;
  }
  .check_color {
    min-width: 80px;
    height: 24px;
    background: #f0f0f0;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    border: none;
    outline: none;
    padding: 0 8px;
    margin-left: 18px;
    line-height: 24px;
  }
}

.diy_content {
  width: 100%;
}
.diy-group-content {
  width: calc(100% - 88px);
  font-size: 14px;
  font-weight: 400;
  color: #333;
  /deep/.el-input {
    width: 280px;
    height: 32px;
    line-height: 32px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
  .diy-radio-group {
    min-height: 32px;
  }
}

.addNav {
  width: 100%;
  height: 32px;
  line-height: 30px;
  border: 1px dashed #ccc;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
  cursor: pointer;
}
.handle {
  cursor: pointer;
  i {
    font-size: 20px;
  }
}
.dy_more {
  display: inline-block;
  width: 28px;
  height: 32px;
  cursor: pointer;
  vertical-align: sub;
  i {
    line-height: 32px;
  }
}

.adjust{
  height: 23px;
  cursor: pointer;
}
.adjust_good{
  height: 50px;
  margin-top: 10px;
  cursor: pointer;
}
.adjust_kong{
  height: 32px;
  margin-top: 10px;
}
.handle{
  cursor: pointer;
}
.class_add {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    padding: 0 10px;
    .add_good {
      width: 32px;
      height: 32px;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      span {
        display: block;
        font-size: 24px;
        color: #f0f0f0;
      }
    }
    .good_title {
      font-size: 12px;
      color: #666;
      margin-left: 10px;
      line-height: 32px;
    }
  }
  .title {
  height: 32px;
  line-height: 32px;
  /deep/.el-input {
    width: 100%;
    height: 32px;
    line-height: 32px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input__suffix{
      .el-input__suffix-inner{
        .el-input__icon{
          line-height: 32px;
        }
      }
    }
  }
  /deep/.el-select{
    width: 100%;
  }
}
.classification {
  
  .shoplister{
    padding:0 10px;
    margin-top: 10px;
  }
  .class_add {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    padding: 0 10px;
    .add_good {
      width: 32px;
      height: 32px;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      span {
        display: block;
        font-size: 24px;
        color: #f0f0f0;
      }
    }
    .good_title {
      font-size: 12px;
      color: #666;
      margin-left: 10px;
      line-height: 32px;
    }
  }
}
</style>