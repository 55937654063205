<template>
  <div class="banner_container">
    <div class="nav-box" :style="{
      backgroundColor:all_color,
    }" @click.stop="amendBnner('classify')">
      <ul v-if="banner.param.classify_style == 1">
        <li v-for="(value, key) in 14" :key="key">
          <div class="ttshop_navtitle">
            <!-- <div class="ttshop-nav-icon">
              <img src="../../../assets/diy/default.png" alt="" />
            </div>
            <div :style="{ color: banner.param.classify_font_color }">名称</div> -->
            <div :style="{ color: banner.param.classify_font_color }" class="ttshop_navtitle_first">一级分类</div>
            <div class="ttshop_navtitle_second">
              <span v-for="(item, index) in 6" :style="{ color: banner.param.classify_font_color }">二级分类</span>
            </div>
          </div>
        </li>
      </ul>
      <ul v-else>
        <li v-for="(value, key) in 14" :key="key">
          <div class="ttshop_subheading">
            <div class="ttshop-nav-icon">
              <img src="../../../assets/diy/default.png" alt="" />
            </div>
            <div :style="{ color: banner.param.classify_font_color }" class="">名称</div>
            <!-- <div :style="{ color: banner.param.classify_font_color }" class="ttshop_navtitle_first">一级分类</div>
            <div class="ttshop_navtitle_second" >
              <span v-for="(item,index) in 6" :style="{ color: banner.param.classify_font_color }">二级分类</span>
            </div> -->
          </div>
        </li>
      </ul>
      <div class="module-edit module-hover-show">
        <div class="module-handle d-flex flex-wrap">
          <div class="module-delete" @click.stop="delbannner('del')">
            <i class="el-icon-close"></i>
          </div>
          <div class="module-copy" @click.stop="delbannner('copy')">
            <i class="el-icon-document-copy"></i>
          </div>
          <div class="module-down" v-if="banner.index < temIndex.length - 1" @click.stop="delbannner('down')">
            <i class="el-icon-arrow-down"></i>
          </div>
          <div class="module-down" v-if="banner.index > 0" @click.stop="delbannner('up')">
            <i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="banner_content" :class="{ active: banner.param.style == 0 }" @click.stop="amendBnner('banner')">
      <div class="marketlastcon swiper-container swiper" :class="{ active: banner.param.fill == 1 }">
        <ul class="clearfloat swiper-wrapper">
          <template>
            <template v-for="(value, key) in banner.param.list">
              <li class="swiper-slide">
                <img :src="value.pic_url" alt="" />
              </li>
            </template>
          </template>
          <template v-if="banner.param.list.length < 2">
            <li class="swiper-slide" v-for="(item, index) in 3 - banner.param.list.length" :key="index">
              <img src="../../../assets/diy/default.png" alt="" />
            </li>
          </template>
        </ul>
      </div>
      <div class="module-edit module-hover-show">
        <div class="module-handle d-flex flex-wrap">
          <div class="module-delete" @click.stop="delbannner('del')">
            <i class="el-icon-close"></i>
          </div>
          <div class="module-copy" @click.stop="delbannner('copy')">
            <i class="el-icon-document-copy"></i>
          </div>
          <div class="module-down" v-if="banner.index < temIndex.length - 1" @click.stop="delbannner('down')">
            <i class="el-icon-arrow-down"></i>
          </div>
          <div class="module-down" v-if="banner.index > 0" @click.stop="delbannner('up')">
            <i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="user-box" @click.stop="amendBnner('quick')">
      <div class="user-t">
        <div class="ttshop_tloding">
         
          <div class="d-flex align-items-center justify-content-center ttshop_user">
            <div class="ttshop_tlodingimg">
              <img class="img-default" src="../../../assets/images/banner_user.svg" alt="" />
            </div>
            <div class="ttshop_userloidng">
              <p class="ttshop_userloidng_call">HI！你好～</p>
              <div class="ttshop_userloidngs clearfloat">
                欢迎登录平台
              </div>
            </div>
          </div>
          <div class="ttshop_newuser">
            <div class="ttshop_newusers clearfloat">
              <div class="resgiter">免费注册</div>
              <div class="login">立即登录</div>
            </div>
          </div>
        </div>
        <div class="banner_user"></div>
      </div>
      <div class="user-b">
        <template v-for="(item, key) in banner.param.icon_list.length > 0
          ? banner.param.icon_list
          : defaultList.banner_icon.icon_list">
          <div class="user-b-item">
            <img class="img-default" :src="item.pic_url" alt />
            <span v-text="item.title"></span>
          </div>
        </template>
      </div>
      <div class="user-c">
        <div class="user-c-title">
          <div class="title-l">
            <img src="../../../assets/diy/zixun.svg" alt="">
            资讯公告</div>
          <div class="title-r">更多</div>
        </div>
        <div class="user-c-list">
          <div class="list-item" v-for="(item, index) in 3" :key="index">
            <div class="list-item-r">TTshop最新小程序最新小程序最新小程序</div>
          </div>
        </div>
      </div>

      <div class="module-edit module-hover-show">
        <div class="module-handle d-flex flex-wrap">
          <div class="module-delete" @click.stop="delbannner('del')">
            <i class="el-icon-close"></i>
          </div>
          <div class="module-copy" @click.stop="delbannner('copy')">
            <i class="el-icon-document-copy"></i>
          </div>
          <div class="module-down" v-if="banner.index < temIndex.length - 1" @click.stop="delbannner('down')">
            <i class="el-icon-arrow-down"></i>
          </div>
          <div class="module-down" v-if="banner.index > 0" @click.stop="delbannner('up')">
            <i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper } from "swiper";
export default {
  props: ["accept"],
  name: "banSwiper",
  data() {
    return {
      banner: this.accept,
      defaultList: {
        banner_icon: {
          icon_list: [
            {
              pic_url: require("../../../assets/diy/default.png"),
              title: "名称",
            },
            {
              pic_url: require("../../../assets/diy/default.png"),
              title: "名称",
            },
            {
              pic_url: require("../../../assets/diy/default.png"),
              title: "名称",
            },
            {
              pic_url: require("../../../assets/diy/default.png"),
              title: "名称",
            },
            {
              pic_url: require("../../../assets/diy/default.png"),
              title: "名称",
            },
            {
              pic_url: require("../../../assets/diy/default.png"),
              title: "名称",
            },
          ],
        },
      },
      sortIndex: 0,
      temIndex: [],
      all_color:'',
    };
  },
  computed: {
    templatePublic() {
      this.temIndex = this.$parent.temp_list;
      let arr = this.banner.param.list;
      return arr;
    },
  },

  watch: {
    templatePublic: {
      handler(newVal, oldVal) {
        this.resswiper();
      },
      deep: true,
    },
    accept: {
      handler(newVal, oldVal) {
        this.$set(this, 'banner', newVal)
        let color = this.hexToRgba(newVal.param.classify_background_color || '#FFFFFF', newVal.param.classify_opacity);
        this.all_color = color;
        this.$set(this, 'all_color', color)
      },
      deep: true,
      immediate: true,
    }
  },
  created() {
    this.$nextTick(() => {
      this.temIndex = this.$parent.temp_list;
    });
  },
  mounted() {
    // 轮播
    this.resswiper()
  },
  methods: {
    resswiper() {
      this.$nextTick(() => {
        var mySwiper = new Swiper(".marketlastcon", {
          autoplay: true,
          loop: true,
          speed: 600,
          direction: "horizontal",
          paginationClickable: true,
          preventClicks: false,
        });
      });
    },
    amendBnner(type) {
      let data = {
        type: type,
        index: this.banner.index,
        id: this.banner.id,
      };
      this.$emit("change", data);
    },
    delbannner(type) {
      let data = {
        type: type,
        index: this.banner.index,
        id: this.banner.id,
      };
      this.$emit("change", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.banner_container {
  display: flex;
  justify-content: space-between;
  width: 1190px;
  margin: 0 auto;
  margin-top: 10px;

  .nav-box {
    // height: 480px;
    
    height: 460px;
    width: 190px;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    padding: 16px 0;

    ul {
      li {
        width: 100%;

        .ttshop_navtitle {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          // height: 32px;
          // line-height: 32px;
          // padding-left: 37px;
          height: 56px;
          padding: 0 12px;
          cursor: pointer;

          .ttshop-nav-icon {
            width: 16px;
            height: 16px;
            float: left;
            position: absolute;
            left: 12px;
            top: 8px;

            img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              display: block;
            }
          }

          .ttshop_navtitle_first {
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
          }

          .ttshop_navtitle_second {
            width: 100%;
            overflow: hidden;
            font-size: 12px;
            font-weight: 400;
            // color: rgba(255,255,255,0.8);
            line-height: 20px;
            white-space: nowrap;

            span {
              font-size: 12px;
              font-weight: 400;

              // color: rgba(255,255,255,0.8);
              &::after {
                content: '/';
              }
            }

            span:last-child {
              &::after {
                content: '';
              }
            }
          }
        }

        .ttshop_subheading {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          height: 32px;
          line-height: 32px;
          padding-left: 37px;
          cursor: pointer;

          .ttshop-nav-icon {
            width: 16px;
            height: 16px;
            float: left;
            position: absolute;
            left: 12px;
            top: 8px;

            img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              display: block;
            }
          }

          .ttshop_navtitle_first {
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
          }

          .ttshop_navtitle_second {
            width: 100%;
            overflow: hidden;
            font-size: 12px;
            font-weight: 400;
            // color: rgba(255,255,255,0.8);
            line-height: 20px;
            white-space: nowrap;

            span {
              font-size: 12px;
              font-weight: 400;

              // color: rgba(255,255,255,0.8);
              &::after {
                content: '/';
              }
            }

            span:last-child {
              &::after {
                content: '';
              }
            }
          }
        }
      }
    }

    &:hover {
      .module-edit {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .banner_content {
    width: 770px;
    // height: 480px;
    height: 460px;
    position: relative;
    overflow: hidden;

    .marketlastcon {
      width: 100%;
      // height: 480px;
      
    height: 460px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }

    .marketlastcon.active {
      img {
        object-fit: contain;
      }
    }

    &:hover {
      .module-edit {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .module-hover-show {
    top: 0;
  }

  .banner_content.active {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
  }

  .user-box {
    // height: 480px;
    height: 460px;
    width: 230px;
    background-color: #fff;
    z-index: 2;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .module-edit {
        visibility: visible;
        opacity: 1;
      }
    }

    .user-t {
      // height: 154px;
      // border-bottom: 1px solid #ebebeb;
      padding-top: 20px;
      box-sizing: border-box;    
      background: linear-gradient(180deg, rgba(237, 42, 32, 0.1) 0%, rgba(247, 84, 67, 0) 100%);

      .banner_user {
        width: 100%;

        .login_defalut {
          height: 78px;
          padding: 0 18px;
          // padding-top: 20px;
          box-sizing: border-box;
          cursor: pointer;

          .defalt_image {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            // margin: 0 auto 12px;
            margin-right: 16px;
            float: left;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .defalut_title {
            width: 110px;
            box-sizing: border-box;
            float: left;
            height: 46px;
            // padding-top: 5px;
            line-height: 23px;

            p {
              text-align: left;
              font-size: 12px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #666666;
              margin-bottom: 5px;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }

        .banner_register {
          width: 56px;
          padding: 10px 0 10px;
          margin: 0 auto;
          position: relative;
          z-index: 1;

          img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .ttshop_userloidngover {
          width: 100%;
          margin-bottom: 14px;

          .ttshop_userloidngovers {
            display: table;
            margin: 0 auto;

            p {
              float: left;
              font-size: 12px;
              color: #333;
              position: relative;
              transition: 0.5s;
              -moz-transition: 0.5s;
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;
              margin-right: 10px;

              &::before {
                content: "";
                width: 1px;
                height: 12px;
                background: #333;
                position: absolute;
                top: 3px;
                right: -5px;
              }

              a {
                font-size: 12px;
                color: #ff0e25;
              }
            }

            strong {
              float: left;
              font-size: 12px;
              color: #333;
              font-weight: 400;
              cursor: pointer;
              transition: 0.5s;
              -moz-transition: 0.5s;
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;

              &:hover {
                color: #ff0e25;
              }
            }
          }
        }

        .ttshop_newuser {
          width: 100%;

          .ttshop_newusers {
            display: table;
            margin: 0 auto;

            .resgiter {
              width: 90px;
              height: 32px;
              background: #ED2A20;
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
            }

            .login {
              width: 90px;
              height: 32px;
              background: #F8F8F8;
              border-radius: 3px;
              border: 1px solid #E6E6E6;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              color: #ED2A20;
              line-height: 20px;
            }
            .userLink {
              width: 165px;
              height: 28px;
              background: #ff8803;
              border-radius: 14px;
              margin: 0 auto;
              display: block;
              font-size: 14px;
              line-height: 28px;
              color: #ffffff;
            }
          }
        }
      }

      .ttshop_tloding {
        // width: 100%;
        margin: 0 10px;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 16px;
        .ttshop_user {
          margin-bottom: 16px;
        }

        .ttshop_tlodingimg {
          // width: 36px;
          // height: 36px;
          width: 68px;
          height: 68px;
          border-radius: 50%;
          // margin: 0 auto 12px;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .ttshop_userloidng {
          // width: 100%;
          margin-left: 4px;

          // display: block;
          // margin-bottom: 18px;
          .ttshop_userloidng_call {
            font-size: 18px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          }

          .ttshop_userloidngs {
            // display: table;
            // margin: 0 auto;
            margin-top: 12px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 16px;

            p {
              float: left;
              font-size: 12px;
              color: #333;
              margin-right: 10px;
              position: relative;
              cursor: pointer;
              transition: 0.5s;
              -moz-transition: 0.5s;
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;

              &::before {
                content: "";
                width: 1px;
                height: 12px;
                background: #333;
                position: absolute;
                top: 3px;
                right: -5px;
              }

              &:hover {
                color: #ff0e25;
              }

              &:last-child {
                margin-right: 0;
              }

              &:last-child::before {
                display: none;
              }
            }
          }
        }

        .ttshop_userloidngover {
          width: 100%;
          margin-bottom: 18px;

          .ttshop_userloidngovers {
            display: table;
            margin: 0 auto;

            p {
              float: left;
              font-size: 12px;
              color: #333;
              position: relative;
              transition: 0.5s;
              -moz-transition: 0.5s;
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;
              margin-right: 10px;

              &::before {
                content: "";
                width: 1px;
                height: 12px;
                background: #333;
                position: absolute;
                top: 3px;
                right: -5px;
              }

              a {
                font-size: 12px;
                color: #ff0e25;
              }
            }

            strong {
              float: left;
              font-size: 12px;
              color: #333;
              font-weight: 400;
              cursor: pointer;
              transition: 0.5s;
              -moz-transition: 0.5s;
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;

              &:hover {
                color: #ff0e25;
              }
            }
          }
        }

        .ttshop_newuser {
          width: 100%;

          .ttshop_newusers {
            // display: table;
            // margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            .resgiter {
              width: 90px;
              height: 32px;
              background: #ED2A20;
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
              margin-right: 18px;
            }

            .login {
              width: 90px;
              height: 32px;
              background: #F8F8F8;
              border-radius: 3px;
              border: 1px solid #E6E6E6;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              color: #ED2A20;
              line-height: 20px;
            }
          }
        }
      }
    }

    .user-c {
      // height: 170px;
      // border-bottom: 1px solid #ebebeb;
      padding: 0 16px;
      box-sizing: border-box;

      .user-c-title {
        // height: 50px;
        padding-top: 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-l {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 14px;
          display: flex;
          align-items: center;
          img{
            width: 15px;
            height: 15px;
            margin-right: 7px;

          }
        }

        .title-r {
          font-size: 12px;
          font-weight: 400;
          color: #ED2A20;
          line-height: 14px;
          transition: color 0.3s;
        }

        .title-r:hover {
          color: #ff0e25;
          font-weight: 500;
        }
      }

      .user-c-list {
        .list-item {
          display: flex;
          align-items: center;
          margin-top: 14px;

          .list-item-l {
            width: 36px;
            height: 20px;
            line-height: 20px;
            background: #fff0f1;
            border-radius: 3px;
            color: #ff0e25;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-shrink: 0;
          }

          .list-item-r {
            width: 100%;
            // margin-left: 8px;
            color: #333333;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .user-b {
      // height: 156px;
      padding: 8px 0px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      margin: 0 10px;
      border-bottom: solid 1px #EDEDED;

      .user-b-item {
        // width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-right: 37px;
        width: 33.33%;
        padding: 8px 0;

        img {
          // height: 32px;
          // width: 32px;
          width: 38px;
          height: 38px;
        }

        span {
          margin-top: 8px;
          line-height: 12px;
        }
      }

      .user-b-item:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
}

.module-edit {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  justify-content: flex-end;

  .module-handle {
    width: 50px;
    height: 44px;

    >div {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #000000;
      border-radius: 2px;
      background-position: center center;
      background-repeat: no-repeat;
      transition: all 0.3s;
      margin: 0 4px 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 15px;
        color: #ccc;
      }
    }
  }
}</style>