<template>
  <div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex">是否展示</div>
      <div class="diy-group-content">
        <el-radio-group
          v-model="footerdetail.footer_advert.is_show"
          class="diy-radio-group d-flex align-items-center"
          @change="radioChange"
        >
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="carousel_wrap d-flex">
      <div class="diy-group-title d-flex"></div>
      <div class="diy-group-content">
        <el-table :data="footerdetail.footer_advert.list" style="width: 100%">
          <el-table-column label="标题" min-width="242">
            <template slot-scope="scope">
              <div class="title">
                <el-input v-model="scope.row.title" @change="titleInput($event,scope)" placeholder="请输入标题"></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="背景图" min-width="223">
            <template slot-scope="scope">
              <div class="backgoundImg d-flex justify-content-center">
                <div class="backPic" v-if="scope.row.pic_url"  @click.stop="uploadImages(scope,'img')">
                  <img :src="scope.row.pic_url" alt="" />
                </div>
                <div
                  class="uploadImage d-flex justify-content-center align-items-center" v-else @click.stop="uploadImages(scope,'img')"
                >
                  <span>+</span>
                </div>
                <div class="upload-img-size">(80*80)</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="排序" min-width="89">
            <template slot-scope="scope">
              <div class="">
                <span
                  class="dy_more"
                  v-show="scope.$index > 0"
                  @click.stop="moreUp(scope)"
                >
                  <i class="el-icon-top"></i>
                </span>
                <span
                  v-show="
                    scope.$index < footerdetail.footer_advert.list.length - 1
                  "
                  class="dy_more"
                  @click.stop="moreDown(scope)"
                >
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="89">
            <template slot-scope="scope">
              <div class="handle" @click.stop="handleTable(scope)">
                <i class="el-icon-delete"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div
          class="addNav d-flex align-items-center justify-content-center"
          @click="appendfoot"
          v-if="footerdetail.footer_advert.list.length < 4"
        >
          <span>+</span>添加
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footFoot",
  props: ["param"],
  data() {
    return {
      footerdetail: this.param,
      optionIndex:-1,
    };
  },
  methods: {
    radioChange(value){
        this.footerdetail.footer_advert.is_show=value;
        this.pubilcCom()
    },
    appendfoot() {
      let data = {
        title: "",
        pic_url:'',
      };
      this.footerdetail.footer_advert.list.push(data);
      this.pubilcCom();
    },
    titleInput(val,scope){
        let index=scope.$index;
      this.footerdetail.footer_advert.list[index].title=val;
      this.pubilcCom()
    },
    handleTable(scope) {
      let index = scope.$index;
      this.footerdetail.footer_advert.list.splice(index, 1);
      this.pubilcCom();
    },
    // 往上挪一位
    moreUp(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.footerdetail.footer_advert.list[index - 1])
      );
      this.$set(
        this.footerdetail.footer_advert.list,
        index - 1,
        this.footerdetail.footer_advert.list[index]
      );
      this.$set(this.footerdetail.footer_advert.list, index, options);
      this.pubilcCom();
    },
    // 往下挪一位
    moreDown(scope) {
      let index = scope.$index;
      var options = JSON.parse(
        JSON.stringify(this.footerdetail.footer_advert.list[index + 1])
      );
      this.$set(
        this.footerdetail.footer_advert.list,
        index + 1,
        this.footerdetail.footer_advert.list[index]
      );
      this.$set(this.footerdetail.footer_advert.list, index, options);
      this.pubilcCom();
    },
    uploadImages(scope,type){
        this.optionIndex=scope.$index;
        this.$emit("change",'',type)
    },
    // 往父级传值
    pubilcCom() {
      let data = this.footerdetail;
      this.$emit("change", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel_wrap {
  margin-top: 10px;
  .diy-group-title {
    width: 88px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #666666;
  }
  .diy-group-content {
    width: calc(100% - 88px);
    font-size: 14px;
    font-weight: 400;
    color: #333;
    /deep/.el-input {
      width: 200px;
      height: 32px;
      line-height: 32px;
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .diy-radio-group {
      min-height: 32px;
    }
  }
  .diy_content {
    width: 100%;
  }
}
.title {
  height: 32px;
  line-height: 32px;
  width: 200px;
  /deep/.el-input {
    width: 200px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}
.backgoundImg {
  .backPic {
    width: 32px;
    height: 32px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .uploadImage {
    width: 32px;
    height: 32px;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    span {
      display: block;
      font-size: 24px;
      color: #f0f0f0;
    }
  }
  .upload-img-size {
    font-size: 12px;
    color: #666;
    margin-left: 10px;
    line-height: 32px;
  }
}
.addNav {
  width: 100%;
  height: 32px;
  line-height: 30px;
  border: 1px dashed #ccc;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
  cursor: pointer;
}
.handle{
  cursor: pointer;
  i{
    font-size: 20px;
  }
}
.dy_more {
  display: inline-block;
  width: 28px;
  height: 32px;
  cursor: pointer;
  vertical-align: sub;
  i {
    line-height: 32px;
  }
}
</style>